import {
  Box,
  Divider,
  Fab,
  Step,
  StepContent,
  StepLabel,
  Stepper,
} from "@mui/material";
import { Add, Delete, MicRounded } from "@mui/icons-material";
import React, { useContext, useEffect, useState } from "react";
import { Composition } from "../../../../../models/composition/composition";
import EditSongForm from "../edit-song-form/edit-song-form";
import H2 from "../../../../../components/layout/titles/h2";
import { Song } from "../../../../../models/song/song";
import ApiUri from "../../../../../api/api-uri";
import API from "../../../../../api/api";
import { GlobalLoaderContext } from "../../../../../context/global-loader";
import { SnackbarContext } from "../../../../../context/snackbar";
import MmdzSyncDialog from "../../common-components/mmdz-sync-dialog";
import ConfirmationDialogActivator from "../../../../../components/confirmation-dialog/confirmation-dialog-activator";

interface ICompositionSongs {
  selectedSong: Song;
  composition: Composition;
  setComposition: Function;
}

export default function CompositionSongs({
  selectedSong,
  composition,
  setComposition,
}: ICompositionSongs) {
  const [activeStep, setActiveStep] = useState(-1);
  const [mmdzSyncDialogOpened, setMmdzSyncDialogOpened] = useState(false);
  const { startGlobalLoader, stopGlobalLoader } =
    useContext(GlobalLoaderContext);
  const { initSnackbarSuccess, initSnackbarError } =
    useContext(SnackbarContext);
  const [editSongFormState, setEditSongFormState] = useState({});
  const [mmdzData, setmmdzData] = useState({});

  const handleFormChange = (songIndex, formData) => {
    setEditSongFormState((prevFormState) => ({
      ...prevFormState,
      [songIndex]: formData,
    }));
  };

  const renderEditSongForm = (song, index) => {
    if (activeStep === index) {
      return (
        <EditSongForm
          songData={song}
          composition={composition}
          setComposition={setComposition}
          songIndex={index}
          openMmdzSyncDialog={openMmdzSyncDialog}
          onMmdzSyncDialogClose={onMmdzSyncDialogClose}
          formData={editSongFormState[index] || {}}
          onFormChange={(formData) => handleFormChange(index, formData)}
          mmdzData={mmdzData}
        />
      );
    }
    return null; // Render nothing if the step is closed
  };

  useEffect(() => {
    composition.songs.map((song, index) => {
      if (song.id === selectedSong.id) {
        setActiveStep(index);
      }
      return song;
    });
  }, []); // eslint-disable-line

  const handleStepLabelClick = (index) => {
    if (index === activeStep) {
      setActiveStep(-1);
      return;
    }

    setActiveStep(index);
  };

  const handleDeleteClick = (song: Song) => {
    startGlobalLoader();
    API.delete(ApiUri.SONG + "/" + song.id).then(
      (response) => {
        stopGlobalLoader();
        initSnackbarSuccess("Song successfully removed");
        let compositionSongs = composition.songs.filter(
          (compositionSong) => compositionSong.id !== song.id
        );
        setComposition((previousState) => ({
          ...previousState,
          songs: compositionSongs,
        }));
      },
      (error) => {
        initSnackbarError(error);
        stopGlobalLoader();
      }
    );
  };

  const handleNewMasterRecordingClick = () => {
    startGlobalLoader();
    API.post(ApiUri.COMPOSITION + "/" + composition.id + ApiUri.SONG, {}).then(
      (response) => {
        let songs = composition.songs;
        response.data.valid = false;
        songs.push(response.data);
        setComposition((previousState) => ({ ...previousState, songs: songs }));
        setActiveStep(composition.songs.length - 1);
        stopGlobalLoader();
        initSnackbarSuccess("Success!");
      },
      (error) => initSnackbarError(error)
    );
  };

  const openMmdzSyncDialog = (song: Song, index: number) => {
    setActiveStep(index);
    setMmdzSyncDialogOpened(true);
  };

  const onMmdzSyncDialogClose = (mmdzSong: Song, songIndex: number) => {
    setMmdzSyncDialogOpened(false);

    if (mmdzSong) {
      let songs = composition.songs.map((song: Song) => {
        if (song.id === mmdzSong.id) {
          song.barcode = mmdzSong?.barcode ? mmdzSong.barcode : song.barcode;
          song.isrc = mmdzSong?.isrc ? mmdzSong.isrc : song.isrc;
          song.release_name = mmdzSong?.release_name
            ? mmdzSong.release_name
            : song.release_name;
          song.release_date = mmdzSong?.release_date
            ? new Date(mmdzSong.release_date)
            : song.release_date;
          song.recording_artists = mmdzSong?.recording_artists
            ? mmdzSong.recording_artists
            : song.recording_artists;
          song.genres =
            mmdzSong?.genres?.length > 0 ? mmdzSong.genres : song.genres;
          song.full_song_version = mmdzSong?.full_song_version?.file?.id
            ? mmdzSong.full_song_version
            : song.full_song_version;
          song.artwork = mmdzSong?.artwork?.file?.id
            ? mmdzSong.artwork
            : song.artwork;
          setmmdzData({
            barcode: mmdzSong?.barcode ? mmdzSong.barcode : song.barcode,
            isrc: mmdzSong?.isrc ? mmdzSong.isrc : song.isrc,
            release_name: mmdzSong?.release_name
              ? mmdzSong.release_name
              : song.release_name,
            release_date: mmdzSong?.release_date
              ? new Date(mmdzSong.release_date)
              : song.release_date,
            recording_artists: mmdzSong?.recording_artists
              ? mmdzSong.recording_artists
              : song.recording_artists,
            genres:
              mmdzSong?.genres?.length > 0 ? mmdzSong.genres : song.genres,
            full_song_version: mmdzSong?.full_song_version?.file?.id
              ? mmdzSong.full_song_version
              : song.full_song_version,
            artwork: mmdzSong?.artwork?.file?.id
              ? mmdzSong.artwork
              : song.artwork,
          });
        }

        return song;
      });

      setComposition((previousState) => ({ ...previousState, songs: songs }));
      handleFormChange(songIndex, songs[songIndex]);

      initSnackbarSuccess(
        "Metadata successfully synced! Please continue to the next step."
      );
    }
  };

  return (
    <React.Fragment>
      <Box marginTop={10}>
        <H2>Master Recordings</H2>
      </Box>

      <Stepper activeStep={activeStep} orientation="vertical">
        {composition.songs.map((song, index) => (
          <Step key={song.id} completed={false}>
            <StepLabel
              icon={<MicRounded />}
              onClick={() => handleStepLabelClick(index)}
              style={{ cursor: "pointer" }}
            >
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                {song.isrc ? "ISRC: " + song.isrc : "ISRC missing"}
                <ConfirmationDialogActivator
                  onClick={() => handleDeleteClick(song)}
                  type="fab"
                  icon={<Delete />}
                  actionName="Delete Song"
                  content="Are you sure you want to delete this song? This action can't be reversed."
                  style={{ marginLeft: 10 }}
                />
              </Box>
            </StepLabel>
            <StepContent>
              {renderEditSongForm(song, index)}
              {mmdzSyncDialogOpened && (
                <MmdzSyncDialog
                  isOpen={mmdzSyncDialogOpened}
                  song={song}
                  onDialogClose={onMmdzSyncDialogClose}
                  songIndex={index}
                />
              )}
            </StepContent>
          </Step>
        ))}
      </Stepper>
      <Fab
        variant="extended"
        size="small"
        color="primary"
        onClick={handleNewMasterRecordingClick}
        style={{ marginTop: 20, marginBottom: 20 }}
      >
        New Master Recording
        <Add />
      </Fab>
    </React.Fragment>
  );
}
