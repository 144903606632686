import React, { useContext, useEffect, useState } from "react";
import { GlobalLoaderContext } from "../../../context/global-loader";
import ApiUri from "../../../api/api-uri";
import API from "../../../api/api";
import { AuthContext } from "../../../context/auth";
import { SnackbarContext } from "../../../context/snackbar";
import { useHistory } from "react-router-dom";
import RoleTypes from "../../../enums/role-types";
import ProfileTypes from "../../../enums/profile-types";
import UnsubmissionReasonsTable from "./UnsubmissionReasonsTable";
import { Stack } from "@mui/material";
import ActionButtonSmall from "../../../components/layout/buttons/action-button-small";
import AddReasonModal from "./AddReasonModal";

const ManageUnsubmissionReasons = () => {
  const { startGlobalLoader, stopGlobalLoader } =
    useContext(GlobalLoaderContext);

  const [
    showManageUnsubmissionReasonsPage,
    setShowManageUnsubmissionReasonsPage,
  ] = useState(false);
  const [
    openAddNewUnsubmissionReasonModal,
    setOpenAddNewUnsubmissionReasonModal,
  ] = useState(false);

  const [unsubmissionReasons, setUnsubmissionReasons] = useState(null);

  const { user, userRole } = useContext(AuthContext);
  const { initSnackbarError } = useContext(SnackbarContext);
  const history = useHistory();

  const seeThisPage =
    (userRole === RoleTypes.OWNER ||
      userRole === RoleTypes.ADMIN ||
      user?.is_mmpz_service_admin) &&
    // user?.last_accessed_profile.profile_type.name ===
    // ProfileTypes.PUBLISHER_ADMINISTRATOR ||
    user?.last_accessed_profile.profile_type.name === ProfileTypes.SERVICE;

  useEffect(() => {
    if (!seeThisPage) {
      initSnackbarError("You don't have permission to access this page.");
      history.push("/dashboard");
      return;
    } else {
      setShowManageUnsubmissionReasonsPage(true);
    }
  }, []);

  useEffect(() => {
    let isMounted = true;
    if (unsubmissionReasons) {
      return;
    }

    API.get(ApiUri.UN_SUBMIT_REASON, {
      params: {
        limit: 100000,
        page: 1,
      },
    }).then((response) => {
      if (isMounted) {
        setUnsubmissionReasons(response.data.collection);
      }
    });

    return () => {
      isMounted = false;
    };
  }, [unsubmissionReasons]); // eslint-disable-line

  const reload = () => {
    setUnsubmissionReasons(null);
  };

  return (
    showManageUnsubmissionReasonsPage && (
      <Stack spacing={2}>
        <ActionButtonSmall
          onClick={() => setOpenAddNewUnsubmissionReasonModal(true)}
        >
          Add new Unsubmission Reason
        </ActionButtonSmall>
        <UnsubmissionReasonsTable
          unsubmissionReasons={unsubmissionReasons}
          setUnsubmissionReasons={setUnsubmissionReasons}
        />
        {openAddNewUnsubmissionReasonModal && (
          <AddReasonModal
            modalOpened={openAddNewUnsubmissionReasonModal}
            handleClose={() => setOpenAddNewUnsubmissionReasonModal(false)}
            reload={reload}
          />
        )}
      </Stack>
    )
  );
};

export default ManageUnsubmissionReasons;
