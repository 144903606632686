import React, { useContext, useEffect, useState } from "react";
import {
  AppBar,
  Autocomplete,
  Dialog,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Close } from "@mui/icons-material";
import dayjs from "dayjs";
import ActionButtonSmall from "../../../components/layout/buttons/action-button-small";
import CustomToolbar from "../../../components/layout/titles/CustomToolbar";
import { GlobalLoaderContext } from "../../../context/global-loader";
import { SnackbarContext } from "../../../context/snackbar";
import API from "../../../api/api";
import ApiUri from "../../../api/api-uri";
import useCountries from "../../../hooks/country/countries.hook";
import makeStyles from "@mui/styles/makeStyles";
import { Country } from "../../../models/country/country";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  textField: {
    marginBottom: "25px",
    padding: "5px",
  },
}));

const EditReasonModal = ({ modalOpened, handleClose, reload, reason }) => {
  const classes = useStyles();
  const [editedReason, setEditedReason] = useState({ ...reason });
  const { startGlobalLoader, stopGlobalLoader } =
    useContext(GlobalLoaderContext);
  const { initSnackbarError, initSnackbarSuccess } =
    useContext(SnackbarContext);

  useEffect(() => {
    if (modalOpened) {
      setEditedReason({ ...reason });
    }
  }, [modalOpened, reason]);

  const closeEditReasonModal = () => {
    handleClose();
  };

  const handleChange = (event) => {
    const { value, name } = event.target;
    setEditedReason((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSave = () => {
    startGlobalLoader();
    API.put(ApiUri.UN_SUBMIT_REASON + "/" + editedReason.id, {
      name: editedReason.name,
    }).then(
      (response) => {
        initSnackbarSuccess("Unsubmission Reason Edited");
        stopGlobalLoader();
        reload();
        closeEditReasonModal();
      },
      (error) => {
        stopGlobalLoader();
        initSnackbarError(error);
      }
    );
  };

  return (
    <React.Fragment>
      <Dialog open={modalOpened} onClose={closeEditReasonModal} maxWidth="xl">
        <AppBar className={classes.appBar}>
          <CustomToolbar>
            <Typography variant="h6" className={classes.title}>
              Edit Unsubmission Reason
            </Typography>
            <IconButton
              color="inherit"
              onClick={closeEditReasonModal}
              aria-label="close"
              size="large"
            >
              <Close />
            </IconButton>
          </CustomToolbar>
        </AppBar>

        <Stack spacing={5} padding={5}>
          <Grid spacing={2} container>
            <Grid item xs={12}>
              <TextField
                value={editedReason?.name ?? ""}
                onChange={handleChange}
                name="name"
                label="Name"
                multiline
                rows={5}
                fullWidth
              />
            </Grid>
          </Grid>
          <ActionButtonSmall
            onClick={handleSave}
            disabled={editedReason?.name.length === 0}
          >
            Save
          </ActionButtonSmall>
        </Stack>
      </Dialog>
    </React.Fragment>
  );
};

export default EditReasonModal;
