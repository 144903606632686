import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import Colors from "../enums/colors";

const PreSignupPage = () => {
  const [region, setRegion] = useState("Rest of the World");

  const handleChangeRegion = (e) => {
    setRegion(e.target.value);
  };

  return (
    <Stack
      sx={{ backgroundColor: Colors.darkCherry }}
      alignItems="center"
      minHeight="100vh"
      paddingX={3}
    >
      <Paper
        sx={{
          width: { sm: "90%", md: "50%" },
          paddingRight: 4,
          paddingLeft: 4,
          paddingTop: 4,
          paddingBottom: 10,
          marginY: 3,
          maxWidth: 600,
          borderRadius: 2,
        }}
        elevation={6}
      >
        <Stack spacing={3} alignItems="center" textAlign="center">
          <img
            src="/MMPZ-logo-round.png"
            alt="MMPZ Logo"
            style={{ width: 150, height: 150 }}
          />
          <Typography variant="h5" fontWeight="bold">
            Welcome to My Music Publishing Zone (MMPZ)
          </Typography>
          <Typography>
            Through <b>MMPZ</b>, you can sign up to Horus Music Publishing,
            which enables you to register your songs with collection societies
            around the world, and allows you to maximise your publishing
            royalties.
          </Typography>
          <Typography>
            Not sure how music publishing works?{" "}
            <a
              href="https://www.horusmusic.global/news/music-publishing-explained/"
              target="_blank"
              style={{ color: "inherit", textDecoration: "underline" }}
            >
              Read our blog
            </a>
          </Typography>
          <Typography>
            Earn more from the songs that you write - Don’t leave money on the
            table!
          </Typography>
          <Typography fontWeight="bold">Where are you based?</Typography>
          <FormControl fullWidth>
            <InputLabel>Region</InputLabel>
            <Select value={region} onChange={handleChangeRegion} label="Region">
              <MenuItem value="Rest of the World">Rest of The World</MenuItem>
              <MenuItem value="India">India</MenuItem>
            </Select>
          </FormControl>
          <Typography fontWeight="bold">
            Which type of publishing account do you wish to create?
          </Typography>
          <Stack
            direction={{ xs: "column", sm: "row" }}
            spacing={3}
            width="100%"
            alignItems="stretch"
          >
            <Paper
              variant="outlined"
              sx={{
                flex: 1,
                borderColor: "#22BEEF",
                borderWidth: 2,
                padding: 3,
                borderRadius: 2,
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                height: "100%",
              }}
            >
              <Stack spacing={2} alignItems="center" flexGrow={1}>
                <Typography variant="h6" sx={{ color: "#22BEEF" }}>
                  Songwriter
                </Typography>
                <Typography variant="body2">
                  {region === "Rest of the World"
                    ? "Only £3 per month"
                    : "Only £36 per year"}{" "}
                  for an individual Songwriter Profile.
                </Typography>
                <Typography variant="body2">
                  Retain 90% of your publishing royalties.
                </Typography>
                <Typography variant="body2">
                  Easily transfer your distributed music to collection
                  societies.
                </Typography>
              </Stack>
              <Button
                href={
                  region === "Rest of the World"
                    ? "/new-profile/registration-form/horus-music-publishing/songwriter"
                    : "/new-profile/registration-form/horus-music-publishing-india/songwriter"
                }
                target="_blank"
                sx={{
                  backgroundColor: "#22BEEF",
                  color: "white",
                  borderRadius: 1,
                  textTransform: "none",
                  alignSelf: "center",
                  marginTop: "auto",
                }}
              >
                I am a Songwriter
              </Button>
            </Paper>
            <Paper
              variant="outlined"
              sx={{
                flex: 1,
                borderColor: "#FFC100",
                borderWidth: 2,
                padding: 3,
                borderRadius: 2,
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                height: "100%",
              }}
            >
              <Stack spacing={2} alignItems="center" flexGrow={1}>
                <Typography variant="h6" sx={{ color: "#FFC100" }}>
                  Publisher
                </Typography>
                <Typography variant="body2">
                  {region === "Rest of the World"
                    ? "From only £5.50 per month"
                    : "From only £66 per year"}{" "}
                  if you represent multiple songwriters or a band.
                </Typography>
                <Typography variant="body2">
                  Retain 90% of your publishing royalties.
                </Typography>
                <Typography variant="body2">
                  Easily transfer your distributed music to collection
                  societies.
                </Typography>
              </Stack>
              <Button
                href={
                  region === "Rest of the World"
                    ? "/new-profile/registration-form/horus-music-publishing/publisher"
                    : "/new-profile/registration-form/horus-music-publishing-india/publisher"
                }
                target="_blank"
                sx={{
                  backgroundColor: "#FFC100",
                  color: "black",
                  borderRadius: 1,
                  textTransform: "none",
                  alignSelf: "center",
                  marginTop: "auto",
                }}
              >
                I am a Publisher
              </Button>
            </Paper>
          </Stack>
        </Stack>
      </Paper>
    </Stack>
  );
};

export default PreSignupPage;
