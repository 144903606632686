import { Info } from "@mui/icons-material";
import { Badge, FormControl, Grid, TextField, Tooltip } from "@mui/material";
import React from "react";
import { SongWriter } from "../../../../../models/composition/song-writer";
import { Composition } from "../../../../../models/composition/composition";
import Compositions from "../../../../../enums/compositions";

interface SongwriterCaeNumberFieldProps {
  newSongwriter: SongWriter;
  setNewSongwriter: React.Dispatch<React.SetStateAction<SongWriter>>;
  currentComposition: Composition;
}

const SongwriterCaeNumberField: React.FC<SongwriterCaeNumberFieldProps> = ({
  newSongwriter,
  setNewSongwriter,
  currentComposition,
}) => {
  const handleChange = (event: any) => {
    const { name, value } = event.target;
    setNewSongwriter((previousState) => ({ ...previousState, [name]: value }));
  };

  return (
    <Grid item xs={12}>
      <FormControl fullWidth>
        <Tooltip
          title="The PRO membership number for the songwriter, also known as IPI number"
          placement="top-end"
        >
          <Badge
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            badgeContent={<Info color="disabled" />}
          />
        </Tooltip>
        <TextField
          name="songwriter_cae_number"
          value={newSongwriter.songwriter_cae_number || ""}
          onChange={handleChange}
          disabled={
            !(
              newSongwriter.external_song_writer &&
              currentComposition.status === Compositions.STATUS_DRAFT
            )
          }
          // required={true}
          type="text"
          label="Songwriter CAE Number"
        />
      </FormControl>
    </Grid>
  );
};

export default SongwriterCaeNumberField;
