import React, { useContext, useEffect, useState } from "react";
import { Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import ApiUri from "../../../api/api-uri";
import API from "../../../api/api";
import { GlobalLoaderContext } from "../../../context/global-loader";
import Platforms from "../../../enums/platforms";
import {
  Box,
  Checkbox,
  Chip,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import { SnackbarContext } from "../../../context/snackbar";
import CustomToolbar from "../../../components/layout/titles/CustomToolbar";
import DateTime from "../../../components/utilities/date-time";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import DateFnsUtils from "@date-io/date-fns";
import ActionButtonSmall from "../../../components/layout/buttons/action-button-small";
import { SdCardSharp } from "@mui/icons-material";
import { AuthContext } from "../../../context/auth";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialog: {
      marginLeft: "40px",
      marginRight: "40px",
      marginTop: "40px",
      marginBottom: "40px",
    },
    appBar: {
      position: "relative",
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    textField: {
      marginBottom: "25px",
      padding: "5px",
    },
  })
);

const Transition = React.forwardRef(function Transition(
  // made the children prop required
  props: TransitionProps & { children: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function RegisterNewTransactionModal({
  modalOpened,
  handleClose,
  setRoyalties,
}) {
  const classes = useStyles();
  const { startGlobalLoader, stopGlobalLoader } =
    useContext(GlobalLoaderContext);
  const { initSnackbarError, initSnackbarSuccess, initSnackbarGeneric } =
    useContext(SnackbarContext);
  const { user } = useContext(AuthContext);

  const [amount, setAmount] = useState("");
  const [datePaid, setDatePaid] = useState<any>(null);
  const [type, setType] = useState("");
  const [description, setDescription] = useState("");

  const closeGenerateIPRSModal = () => {
    setAmount("");
    setDatePaid(null);
    setType("");
    setDescription("");
    handleClose();
  };

  const handleChange = (event) => {
    const name = event.target.name;
    let value = event.target.value;
    if (name === "amount") {
      value = value.replace(/,/g, ".").replace(/^\.|,$/g, "");

      // Validate the input using a regular expression
      const isValidInput = /^(?!-)\d*(\.\d{0,2})?$/.test(value);

      if (isValidInput) {
        setAmount(value);
      }
    }
    if (name === "type") {
      setType(value);
    }
    if (name === "description") {
      setDescription(value);
    }
  };

  const handleEndDateChange = (newValue) => {
    setDatePaid(newValue?.$d || null);
  };

  const handleSave = () => {
    const formattedDatePaid = datePaid
      ? new Date(
          datePaid.getFullYear(),
          datePaid.getMonth(),
          datePaid.getDate(),
          12 // Set a fixed time to avoid timezone offsets
        ).toISOString()
      : "";

    const payload = {
      type,
      date_paid: formattedDatePaid,
      amount: parseFloat(amount),
      description,
    };

    startGlobalLoader();
    API.post(
      ApiUri.PROFILE +
        "/" +
        user?.last_accessed_profile?.id +
        ApiUri.ROYALTY_ADJUST,
      payload
    ).then(
      () => {
        initSnackbarSuccess("New Transaction Successfully Registered");
        setRoyalties(null);
        closeGenerateIPRSModal();
        stopGlobalLoader();
      },
      (error) => {
        initSnackbarError(error);
        stopGlobalLoader();
      }
    );
  };

  const formIsValid = datePaid;

  return (
    <React.Fragment>
      <Dialog
        className={classes.dialog}
        fullScreen
        open={modalOpened}
        onClose={closeGenerateIPRSModal}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <CustomToolbar>
            <Typography variant="h6" className={classes.title}>
              Register New Transaction
            </Typography>
            <IconButton
              color="inherit"
              onClick={closeGenerateIPRSModal}
              aria-label="close"
              size="large"
            >
              <CloseIcon />
            </IconButton>
          </CustomToolbar>
        </AppBar>

        <Grid container marginTop={2} padding={3} spacing={2}>
          <Grid item xs={12} md={4}>
            <TextField
              fullWidth
              label="Amount"
              value={amount}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">£</InputAdornment>
                ),
              }}
              name="amount"
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Date Paid"
                value={datePaid}
                onChange={handleEndDateChange}
                format="DD/MM/YYYY"
                disableFuture
                sx={{ width: "100%" }}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} md={4}>
            <FormControl fullWidth>
              <InputLabel required>Type</InputLabel>
              <Select
                name="type"
                label="Type"
                value={type}
                onChange={handleChange}
                fullWidth
              >
                <MenuItem value="payment">Payment</MenuItem>
                <MenuItem value="return payment">Return Payment</MenuItem>
                <MenuItem value="other payment">Other Payment</MenuItem>
                <MenuItem value="other received payment">
                  Other Received Payment
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
              multiline
              fullWidth
              rows={4}
              label="Description"
              value={description}
              name="description"
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <ActionButtonSmall onClick={handleSave} disabled={!formIsValid}>
              Save
            </ActionButtonSmall>
          </Grid>
        </Grid>
      </Dialog>
    </React.Fragment>
  );
}
