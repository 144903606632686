import { Box, Grid, Paper, TextField, Typography } from "@mui/material";
import React from "react";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { Theme } from "@mui/material/styles";
import { Song } from "../../../../models/song/song";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import FileDownload from "../../../../components/utilities/file-download";
import { PlayCircleFilled } from "@mui/icons-material";
import { Composition } from "../../../../models/composition/composition";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    gridItem: {
      padding: "20px",
    },
    textField: {
      marginBottom: "25px",
      padding: "5px",
    },
  })
);

interface ISongCard {
  song: Song;
  composition: Composition;
}

export default function SongsCard({ song, composition }: ISongCard) {
  const classes = useStyles();

  return song ? (
    <Grid item xs={12} className={classes.gridItem}>
      <Paper elevation={3} style={{ height: "100%" }}>
        <Box p={2}>
          <Grid container>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                disabled
                className={classes.textField}
                label="Release Name"
                value={song.release_name || ""}
                InputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                disabled
                className={classes.textField}
                label="ISRC"
                value={song.isrc || ""}
                InputProps={{ readOnly: true }}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                disabled
                className={classes.textField}
                label="Artist Name"
                value={
                  song.recording_artists
                    ? song.recording_artists.map((item) => item.name).join(", ")
                    : ""
                }
                InputProps={{ readOnly: true }}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                disabled
                className={classes.textField}
                label="Release Date"
                value={
                  song.release_date
                    ? new Date(song.release_date).toLocaleDateString()
                    : ""
                }
                InputProps={{ readOnly: true }}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                disabled
                className={classes.textField}
                label="Record Label Name"
                InputProps={{ readOnly: true }}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                disabled
                className={classes.textField}
                label="Barcode"
                value={song.barcode || ""}
                InputProps={{ readOnly: true }}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                disabled
                className={classes.textField}
                label="Duration"
                value={song.duration || ""}
                InputProps={{ readOnly: true }}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                disabled
                className={classes.textField}
                label="BPM"
                value={song.bpm || ""}
                InputProps={{ readOnly: true }}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                disabled
                className={classes.textField}
                label="Is Instrumental?"
                value={composition?.is_instrumental ? "Yes" : "No"}
                InputProps={{ readOnly: true }}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                disabled
                className={classes.textField}
                label="Is PPL Registered?"
                value={song.is_ppl_registered ? "Yes" : "No"}
                InputProps={{ readOnly: true }}
              />
            </Grid>

            <Grid item xs={12}>
              {song.full_song_version ? (
                <React.Fragment>
                  <Grid item xs={12}>
                    <Typography variant="overline">Full version</Typography>
                  </Grid>
                  <AudioPlayer
                    style={{ marginBottom: 10 }}
                    autoPlayAfterSrcChange={false}
                    showSkipControls={false}
                    showJumpControls={false}
                    customVolumeControls={[]}
                    customAdditionalControls={[]}
                    layout="horizontal-reverse"
                    src={song.full_song_version.file.url}
                    customIcons={{
                      play: (
                        <PlayCircleFilled
                          color="primary"
                          style={{ fontSize: "1em" }}
                        />
                      ),
                    }}
                  />
                  <FileDownload file={song.full_song_version?.file} />
                </React.Fragment>
              ) : null}
            </Grid>

            <Grid item xs={12}>
              {song.instrumental_song_version ? (
                <React.Fragment>
                  <Grid item xs={12} style={{ marginTop: 40 }}>
                    <Typography variant="overline">
                      Instrumental version
                    </Typography>
                  </Grid>
                  <AudioPlayer
                    style={{ marginBottom: 10 }}
                    autoPlayAfterSrcChange={false}
                    showSkipControls={false}
                    showJumpControls={false}
                    customVolumeControls={[]}
                    customAdditionalControls={[]}
                    layout="horizontal-reverse"
                    src={song.instrumental_song_version.file.url}
                    customIcons={{
                      play: (
                        <PlayCircleFilled
                          color="primary"
                          style={{ fontSize: "1em" }}
                        />
                      ),
                    }}
                  />
                  <FileDownload file={song.instrumental_song_version?.file} />
                </React.Fragment>
              ) : null}
            </Grid>

            <Grid item xs={12}>
              {song.extra_instrumental_song_version ? (
                <React.Fragment>
                  <Grid item xs={12} style={{ marginTop: 40 }}>
                    <Typography variant="overline">
                      Extra instrumental version
                    </Typography>
                  </Grid>
                  <AudioPlayer
                    style={{ marginBottom: 10 }}
                    autoPlayAfterSrcChange={false}
                    showSkipControls={false}
                    showJumpControls={false}
                    customVolumeControls={[]}
                    customAdditionalControls={[]}
                    layout="horizontal-reverse"
                    src={song.extra_instrumental_song_version.file.url}
                    customIcons={{
                      play: (
                        <PlayCircleFilled
                          color="primary"
                          style={{ fontSize: "1em" }}
                        />
                      ),
                    }}
                  />
                  <FileDownload
                    file={song.extra_instrumental_song_version?.file}
                  />
                </React.Fragment>
              ) : null}
            </Grid>

            {song.clean_song_version ? (
              <React.Fragment>
                <Grid item xs={12} style={{ marginTop: 40 }}>
                  <Typography variant="overline">Clean version</Typography>
                </Grid>
                <Grid item xs={12}>
                  <React.Fragment>
                    <AudioPlayer
                      style={{ marginBottom: 10 }}
                      autoPlayAfterSrcChange={false}
                      showSkipControls={false}
                      showJumpControls={false}
                      customVolumeControls={[]}
                      customAdditionalControls={[]}
                      layout="horizontal-reverse"
                      src={song.clean_song_version.file.url}
                      customIcons={{
                        play: (
                          <PlayCircleFilled
                            color="primary"
                            style={{ fontSize: "1em" }}
                          />
                        ),
                      }}
                    />
                    <FileDownload file={song.clean_song_version?.file} />
                  </React.Fragment>
                </Grid>
              </React.Fragment>
            ) : null}
          </Grid>
        </Box>
      </Paper>
    </Grid>
  ) : null;
}
