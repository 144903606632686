import {
  CircularProgress,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import React, { useContext, useState } from "react";
import ApiUri from "../../../api/api-uri";
import API from "../../../api/api";
import ActionButtonSmall from "../../../components/layout/buttons/action-button-small";
import { GlobalLoaderContext } from "../../../context/global-loader";
import { SnackbarContext } from "../../../context/snackbar";
import { Profile } from "../../../models/profile/profile";
import useProfiles from "../../../hooks/profile/profiles.hook";
import ProfileTypes from "../../../enums/profile-types";

interface IChangeParent {
  profile: Profile;
}

interface FormData {
  parent: {
    id: number;
  };
}

export default function ChangeParent({ profile }: IChangeParent) {
  const { startGlobalLoader, stopGlobalLoader } =
    useContext(GlobalLoaderContext);
  const { initSnackbarSuccess, initSnackbarError } =
    useContext(SnackbarContext);
  const [formData, setFormData] = useState<FormData>(profile);
  const profiles = useProfiles();

  const handleFieldChange = (event: any) => {
    const { name, value } = event.target;

    const newParent = profiles.find((row: Profile) => row.id === value);

    if (name === "parent.id" && newParent) {
      formData.parent = newParent;
    }

    setFormData((previousState) => ({ ...previousState, ...formData }));
  };

  const handleOnSubmit = (event: any) => {
    event.preventDefault();
    startGlobalLoader();

    API.put(ApiUri.PROFILE + "/" + profile.id, formData).then(
      (response) => {
        stopGlobalLoader();
        initSnackbarSuccess("Profile successfully edited");
      },
      (error) => {
        stopGlobalLoader();
        initSnackbarError(error);
      }
    );
  };

  const isOptionDisabled = (newProfile: Profile) => {
    if (
      profile.profile_type.name === ProfileTypes.PUBLISHER &&
      newProfile.profile_type.name === ProfileTypes.PUBLISHER_ADMINISTRATOR
    ) {
      return false;
    }
    if (
      profile.profile_type.name === ProfileTypes.SONGWRITER &&
      newProfile.profile_type.name === ProfileTypes.PUBLISHER
    ) {
      return false;
    }
    if (
      profile.profile_type.name === ProfileTypes.SONGWRITER &&
      newProfile.profile_type.name === ProfileTypes.PUBLISHER_ADMINISTRATOR
    ) {
      return false;
    }

    return true;
  };

  return formData ? (
    <form
      autoComplete="off"
      onSubmit={handleOnSubmit}
      style={{ width: "100%" }}
    >
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <FormControl fullWidth style={{ padding: 5 }}>
            <InputLabel>Parent Profile *</InputLabel>
            <Select
              fullWidth
              placeholder={profile.parent.profile_information.name}
              name="parent.id"
              value={formData.parent.id}
              required={true}
              onChange={handleFieldChange}
              startAdornment={
                profiles.length === 0 ? (
                  <InputAdornment position="start">
                    <CircularProgress size={25} />
                  </InputAdornment>
                ) : null
              }
            >
              {profiles.map((profile: Profile) => (
                <MenuItem
                  key={profile?.id}
                  value={profile?.id}
                  disabled={isOptionDisabled(profile)}
                >
                  {profile?.profile_information?.name} (
                  <small>{profile?.profile_type?.name}</small>)
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      <Grid style={{ marginTop: 40 }} container direction="row">
        <ActionButtonSmall type="submit">Save</ActionButtonSmall>
      </Grid>
    </form>
  ) : null;
}
