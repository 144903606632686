import React, { useContext, useState, useEffect } from "react";
import {
  Avatar,
  Badge,
  Box,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Menu,
  Typography,
  CircularProgress,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Notifications } from "@mui/icons-material";
import { NotificationsContext } from "../context/notifications";
import DateTime from "../components/utilities/date-time";
import Colors from "../enums/colors";
import ApiUri from "../api/api-uri";
import API from "../api/api";
import { Notification } from "../models/notification/notification";
import { CustomisationContext } from "../context/customisation";
import { AuthContext } from "../context/auth";

const useStyles = makeStyles(() => ({
  profileDropdownBox: {
    marginRight: 10,
    "&:hover": {
      cursor: "pointer",
    },
  },
  scrollList: {
    height: "80vh",
    overflowY: "auto",
  },
}));

export default function NotificationsDropdown() {
  const { user } = useContext(AuthContext);
  const classes = useStyles();
  const { customisation } = useContext(CustomisationContext);
  const {
    notifications,
    setNotifications,
    unreadNotificationsCount,
    setUnreadNotificationsCount,
  } = useContext(NotificationsContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const [page, setPage] = useState(2); // Initialize page count at 1
  const [loading, setLoading] = useState(false); // Loading state to prevent duplicate requests

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);

    if (unreadNotificationsCount === 0) {
      return;
    }

    API.post(ApiUri.USER_ME + ApiUri.NOTIFICATIONS + ApiUri.READ_ALL).then(
      (response) => {
        setNotifications(response.data.collection);
        setUnreadNotificationsCount(0);
      }
    );
  };

  const fetchNotifications = async () => {
    if (loading) return; // Prevent multiple fetches

    setLoading(true);

    API.get(ApiUri.USER + "/" + user?.id + ApiUri.NOTIFICATION, {
      params: { page: page, limit: 5 },
    }).then(
      (response) => {
        const { notifications } = response.data;
        setNotifications((prevNotifications) => [
          ...prevNotifications,
          ...notifications,
        ]);
        setPage((prevPage) => prevPage + 1);
        setLoading(false);
      },
      (error) => {
        setLoading(false);
        console.log(error);
      }
    );
  };

  const handleScroll = (event) => {
    const { scrollTop, scrollHeight, clientHeight } = event.target;
    console.log("🚀 ~ handleScroll ~ scrollTop:", scrollTop);
    console.log("🚀 ~ handleScroll ~ scrollHeight:", scrollHeight);
    console.log("🚀 ~ handleScroll ~ clientHeight:", clientHeight);

    // Check if near the bottom (e.g., within 50px of the bottom)
    const isNearBottom = scrollHeight - scrollTop <= clientHeight + 50;
    if (isNearBottom && !loading) {
      fetchNotifications();
    }
  };

  const handleOnNotificationClick = (notification: Notification) => {
    handleClose();
  };

  useEffect(() => {
    if (anchorEl) {
      fetchNotifications();
    }
  }, [anchorEl]); // Load first page of notifications when menu opens

  return (
    <React.Fragment>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
        className={classes.profileDropdownBox}
        onClick={handleClick}
      >
        <IconButton size="large">
          <Badge
            badgeContent={unreadNotificationsCount}
            overlap="rectangular"
            color="error"
          >
            <Notifications sx={{ color: customisation?.textColor }} />
          </Badge>
        </IconButton>
      </Box>

      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <List
          className={classes.scrollList}
          onScroll={handleScroll} // Add scroll event handler
          style={{ maxWidth: "400px" }}
        >
          {notifications.length > 0 ? (
            notifications.map((notification) => (
              <ListItem
                key={notification.id}
                alignItems="flex-start"
                button
                divider
                style={{ opacity: notification.is_read ? "0.8" : "1" }}
                onClick={() => handleOnNotificationClick(notification)}
              >
                <Badge
                  color="error"
                  variant={notification.is_read ? "standard" : "dot"}
                  anchorOrigin={{ vertical: "top", horizontal: "left" }}
                >
                  <ListItemAvatar>
                    <Avatar
                      alt="N"
                      src={"/mmpz-logo-small.png"}
                      style={{ backgroundColor: "lightgray" }}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    style={{
                      padding: 20,
                      maxWidth: "320px",
                      wordBreak: "break-word",
                    }}
                    primary={notification.notification_title}
                    secondary={
                      <React.Fragment>
                        <Typography
                          color="primary"
                          variant="subtitle1"
                          component="span"
                        >
                          <b>{notification.notification_text}</b>
                        </Typography>
                        <br />
                        <Typography variant="caption" component="span">
                          <DateTime>{notification.created_at}</DateTime>
                        </Typography>
                      </React.Fragment>
                    }
                  />
                </Badge>
              </ListItem>
            ))
          ) : (
            <ListItem>No notifications!</ListItem>
          )}
          {loading && (
            <ListItem>
              <Box display="flex" justifyContent="center" width="100%">
                <CircularProgress size={24} />
              </Box>
            </ListItem>
          )}
        </List>
      </Menu>
    </React.Fragment>
  );
}
