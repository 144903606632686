import {
  Chip,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext, useState } from "react";
import { Check, Email, Phone } from "@mui/icons-material";
import RoleTypes from "../../../enums/role-types";
import { postCommunicationSettings } from "../../../api/utils/post/postCommunicationSettings";
import { deleteCommunicationSetting } from "../../../api/utils/delete/deleteCommunicationSetting";
import { putProfile } from "../../../api/utils/put/putProfile";
import { GlobalLoaderContext } from "../../../context/global-loader";
import { SnackbarContext } from "../../../context/snackbar";
import { AuthContext } from "../../../context/auth";
import ProfileCommunicationSettingsTypes from "../../../enums/profile-communication-setting-types";
import { Profile } from "../../../models/profile/profile";
import { ProfileCommunicationSettings } from "../../../models/profile/profile-communication-settings";

interface ICommunicationSettings {
  profile: Profile;
  currentUserRole: any;
}

interface ICommunicationSettings {
  profile: Profile;
  currentUserRole: any;
}

export default function CommunicationSettings({
  profile,
  currentUserRole,
}: ICommunicationSettings) {
  const [profileCommunicationSettings, setProfileCommunicationSettings] =
    useState<ProfileCommunicationSettings[]>(
      profile.profile_communication_settings
    );
  const { initSnackbarSuccess, initSnackbarError } =
    useContext(SnackbarContext);
  const { startGlobalLoader, stopGlobalLoader } =
    useContext(GlobalLoaderContext);
  const { user } = useContext(AuthContext);

  const [emailInputs, setEmailInputs] = useState({
    general: "",
    legal: "",
    accounts: "",
  });

  const [formData, setFormData] = useState<Profile>(profile);

  const disableEverything =
    currentUserRole !== RoleTypes.OWNER &&
    currentUserRole !== RoleTypes.ADMIN &&
    !user?.is_mmpz_service_admin;

  const handleNewEmailSubmit = async (event, type: string) => {
    event.preventDefault();
    const newEmail = emailInputs[type.toLowerCase()];
    if (!newEmail.trim()) return; // Prevent empty submissions
    startGlobalLoader();

    try {
      const communicationSettingsResponse = await postCommunicationSettings({
        profileId: profile.id,
        data: { email: newEmail, type },
      });
      const newSetting = communicationSettingsResponse.data;

      setProfileCommunicationSettings((prev) => [...prev, newSetting]);

      setEmailInputs((prev) => ({ ...prev, [type.toLowerCase()]: "" }));
      initSnackbarSuccess("Email successfully added");
    } catch (err) {
      initSnackbarError("Failed to add email. Please try again.");
    } finally {
      stopGlobalLoader();
    }
  };

  const handleEmailChange = (type: string, value: string) => {
    setEmailInputs((prev) => ({ ...prev, [type.toLowerCase()]: value }));
  };

  const handleDelete = async (item: ProfileCommunicationSettings) => {
    startGlobalLoader();

    try {
      await deleteCommunicationSetting({
        profileId: profile.id,
        itemId: item.id,
      });

      setProfileCommunicationSettings((prev) =>
        prev.filter((row) => row.id !== item.id)
      );
      initSnackbarSuccess("Email successfully removed");
    } catch (error) {
      initSnackbarError("Failed to remove email. Please try again.");
    } finally {
      stopGlobalLoader();
    }
  };

  const renderEmailSection = (type: string, title: string) => {
    const filteredEmails = profileCommunicationSettings.filter(
      (item) => item.type === type
    );

    return (
      <Grid item>
        <Typography variant="h6" color="primary">
          {title}
        </Typography>
        <Grid container spacing={2}>
          {filteredEmails.map((item) => (
            <Grid key={item.id} item>
              <Chip
                label={item.email}
                onDelete={() => handleDelete(item)}
                disabled={disableEverything}
              />
            </Grid>
          ))}
          <Grid container spacing={2} style={{ marginTop: "10px" }}>
            <Grid item>
              <form
                autoComplete="off"
                onSubmit={(event) => handleNewEmailSubmit(event, type)}
              >
                <FormControl>
                  <TextField
                    disabled={disableEverything}
                    required
                    type="email"
                    label="Add new"
                    placeholder="Email address..."
                    value={emailInputs[type.toLowerCase()]}
                    onChange={(e) => handleEmailChange(type, e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Email />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            color="primary"
                            type="submit"
                            edge="end"
                            size="large"
                            disabled={disableEverything}
                          >
                            <Check />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
              </form>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const handlePhoneNumberChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const updatedPhoneNumber = event.target.value;
    setFormData((prevState) => ({
      ...prevState,
      profile_information: {
        ...prevState.profile_information,
        phone_number: updatedPhoneNumber,
      },
    }));
  };

  const handlePhoneNumberSubmit = async (event) => {
    event.preventDefault();
    startGlobalLoader();

    try {
      await putProfile({ profileId: profile.id, formData });
      initSnackbarSuccess("Phone number successfully updated");
    } catch (error) {
      initSnackbarError("Failed to update phone number. Please try again.");
    } finally {
      stopGlobalLoader();
    }
  };

  return (
    <Grid container direction="column" spacing={6}>
      {renderEmailSection(ProfileCommunicationSettingsTypes.GENERAL, "General")}
      {renderEmailSection(ProfileCommunicationSettingsTypes.LEGAL, "Legal")}
      {renderEmailSection(
        ProfileCommunicationSettingsTypes.ACCOUNTS,
        "Accounts"
      )}

      <Grid item>
        <Typography variant="h6" color="primary">
          Phone Number
        </Typography>
        <form autoComplete="off" onSubmit={handlePhoneNumberSubmit}>
          <FormControl>
            <TextField
              disabled={disableEverything}
              required
              type="text"
              label="Profile phone number"
              placeholder="Phone number..."
              value={formData.profile_information.phone_number ?? ""}
              onChange={handlePhoneNumberChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Phone />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      color="primary"
                      type="submit"
                      edge="end"
                      size="large"
                      disabled={disableEverything}
                    >
                      <Check />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>
        </form>
      </Grid>
    </Grid>
  );
}
