import React, { useCallback, useContext, useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Loader from "../../../components/loader";
import API from "../../../api/api";
import { AuthContext, IAuthContext } from "../../../context/auth";
import ApiUri from "../../../api/api-uri";
import DateTime from "../../../components/utilities/date-time";
import {
  Box,
  debounce,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { CheckCircle, RemoveCircle, Search } from "@mui/icons-material";
// import ContextMenu from "./context-menu";
import { Profile } from "../../../models/profile/profile";
import ProfileTypes from "../../../enums/profile-types";
// import AboutSubProfileModal from "about-sub-profile-modal/index";
import SmallAvatar from "../../../components/avatars/small-avatar";
import { GlobalLoaderContext } from "../../../context/global-loader";
import { SnackbarContext } from "../../../context/snackbar";
import RoleTypes from "../../../enums/role-types";
import { useHistory } from "react-router-dom";
import ContextMenu from "./ContextMenu";

export default function SubProfilesTable() {
  const { startGlobalLoader, stopGlobalLoader } =
    useContext(GlobalLoaderContext);
  const { initSnackbarError } = useContext(SnackbarContext);
  const { user } = useContext<IAuthContext>(AuthContext);
  const [importedRoyaltyStatements, setImportedRoyaltyStatements] =
    useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [modalOpened, setModalOpened] = useState(false);
  const [selectedProfile, setSelectedProfile] = useState<Profile>();
  const history = useHistory();

  useEffect(() => {
    let isMounted = true;
    if (importedRoyaltyStatements) {
      return;
    }

    API.get(
      ApiUri.PROFILE +
        "/" +
        user?.last_accessed_profile.id +
        ApiUri.ROYALTY_IMPORTER_FILE,
      {
        params: {
          limit: rowsPerPage,
          page: page + 1,
        },
      }
    ).then((response) => {
      if (isMounted) {
        setTotalRows(response.data.meta.total);
        setImportedRoyaltyStatements(response.data.collection);
      }
    });

    return () => {
      isMounted = false;
    };
  }, [importedRoyaltyStatements]); // eslint-disable-line

  const reload = () => {
    setImportedRoyaltyStatements(null);
  };

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
    setImportedRoyaltyStatements(null);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(event.target.value);
    setPage(0);
    setImportedRoyaltyStatements(null);
  };

  return importedRoyaltyStatements ? (
    <React.Fragment>
      <Paper elevation={3} sx={{ marginBottom: 3 }}>
        <TableContainer>
          <Table>
            <TableHead style={{ borderBottom: "2px solid" }}>
              <TableRow>
                <TableCell>
                  <b>Imported</b>
                </TableCell>
                <TableCell>
                  <b>State</b>
                </TableCell>
                <TableCell>
                  <b>Name</b>
                </TableCell>
                <TableCell>
                  <b>Date From</b>
                </TableCell>
                <TableCell>
                  <b>Date To</b>
                </TableCell>
                <TableCell>
                  <b>Platform</b>
                </TableCell>
                <TableCell>
                  <b>GBP</b>
                </TableCell>
                <TableCell>
                  <b>Entered</b>
                </TableCell>
                <TableCell>
                  <b>Total Rows</b>
                </TableCell>
                <TableCell>
                  <b>Imported Rows</b>
                </TableCell>
                <TableCell>
                  <b>Error Rows</b>
                </TableCell>
                <TableCell>
                  <b>Imported By</b>
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            {/* <TableBody style={{ cursor: "pointer" }}> */}
            <TableBody>
              {(importedRoyaltyStatements as any)
                .filter((row) => row.status !== "deleted")
                .map((row: any) => {
                  return (
                    <TableRow hover key={row.id}>
                      {/* <T}
                    <TableCell onClick={() => handleRowCellClick(row)}>
                    {/* <TableCell onClick={() => handleRowCellClick(row)}> */}
                      <TableCell>
                        <DateTime onlyDate>{row?.created_at}</DateTime>
                      </TableCell>
                      <TableCell>
                        {/* <TableCell onClick={() => handleRowCellClick(row)}> */}
                        {row?.status
                          ? row?.status.charAt(0).toUpperCase() +
                            row?.status.slice(1)
                          : ""}
                      </TableCell>
                      <TableCell>
                        {/* <TableCell onClick={() => handleRowCellClick(row)}> */}
                        {row?.file?.original_name}
                      </TableCell>
                      <TableCell>
                        {/* <TableCell onClick={() => handleRowCellClick(row)}> */}
                        <DateTime onlyDate>{row?.date_from}</DateTime>
                      </TableCell>
                      <TableCell>
                        {/* <TableCell onClick={() => handleRowCellClick(row)}> */}
                        <DateTime onlyDate>{row?.date_to}</DateTime>
                      </TableCell>
                      <TableCell>
                        {/* <TableCell onClick={() => handleRowCellClick(row)}> */}
                        {row?.platform?.name}
                      </TableCell>
                      <TableCell sx={{ whiteSpace: "nowrap" }}>
                        {/* <TableCell onClick={() => handleRowCellClick(row)}> */}
                        £ {row?.gbp?.toFixed(2)}
                      </TableCell>
                      <TableCell>
                        {/* <TableCell onClick={() => handleRowCellClick(row)}> */}
                        <DateTime onlyDate>{row?.entered_at}</DateTime>
                      </TableCell>
                      <TableCell>
                        {/* <TableCell onClick={() => handleRowCellClick(row)}> */}
                        {row?.total_rows}
                      </TableCell>
                      <TableCell>
                        {/* <TableCell onClick={() => handleRowCellClick(row)}> */}
                        {row?.imported_rows}
                      </TableCell>
                      <TableCell>
                        {/* <TableCell onClick={() => handleRowCellClick(row)}> */}
                        {row?.error_rows}
                      </TableCell>
                      <TableCell>
                        {/* <TableCell onClick={() => handleRowCellClick(row)}> */}
                        {row?.created_by?.email}
                      </TableCell>

                      <TableCell>
                        <ContextMenu
                          reload={reload}
                          statement={row}
                          setImportedRoyaltyStatements={
                            setImportedRoyaltyStatements
                          }
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100]}
          component="div"
          count={totalRows}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      {/* <AboutSubProfileModal
        modalOpened={modalOpened}
        handleClose={handleModalClose}
        profileId={selectedProfile?.id}
      /> */}
    </React.Fragment>
  ) : (
    <Loader message="Loading Royalty Statements" />
  );
}
