import {
  Box,
  Grid,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import React, { useContext } from "react";
import { SongWriter } from "../../../../../models/composition/song-writer";
import { AssignmentInd, Delete, Edit } from "@mui/icons-material";
import { Composition } from "../../../../../models/composition/composition";
import SongwriterForm from "./SongwriterForm";
import { SnackbarContext } from "../../../../../context/snackbar";
import { GlobalLoaderContext } from "../../../../../context/global-loader";
import ApiUri from "../../../../../api/api-uri";
import API from "../../../../../api/api";

interface SongwritersStepperProps {
  activeSongwriterIndex: number;
  setActiveSongwriterIndex: React.Dispatch<React.SetStateAction<number>>;
  currentComposition: Composition;
  setCurrentComposition: React.Dispatch<React.SetStateAction<Composition>>;
  setTotalShares: React.Dispatch<React.SetStateAction<number>>;
}

const SongwritersStepper: React.FC<SongwritersStepperProps> = ({
  activeSongwriterIndex,
  setActiveSongwriterIndex,
  currentComposition,
  setCurrentComposition,
  setTotalShares,
}) => {
  const { initSnackbarSuccess, initSnackbarError } =
    useContext(SnackbarContext);
  const { startGlobalLoader, stopGlobalLoader } =
    useContext(GlobalLoaderContext);

  const openSongwriter = (index: number) => {
    index === activeSongwriterIndex
      ? setActiveSongwriterIndex(-1)
      : setActiveSongwriterIndex(index);
  };

  const saveSongwriterCallback = (
    newSongwriter: SongWriter,
    oldSongwriter: SongWriter
  ) => {
    const existingSongWriters = currentComposition.song_writers;

    setTotalShares((pastShares) =>
      parseFloat(
        (pastShares - oldSongwriter.share + newSongwriter.share).toFixed(2)
      )
    );

    const newSongWriters = existingSongWriters.map((existingSongwriter) => {
      if (existingSongwriter.id === newSongwriter.id) {
        return newSongwriter;
      }
      return existingSongwriter;
    });

    setCurrentComposition((previousState) => ({
      ...previousState,
      song_writers: newSongWriters,
    }));

    initSnackbarSuccess("Songwriter saved");
    setActiveSongwriterIndex(-1);
  };

  const deleteSongwriterCallback = (index: number) => {
    startGlobalLoader();

    const existingSongWriters = currentComposition.song_writers;
    const songwriterToBeDeleted = currentComposition.song_writers[index];

    setTotalShares((pastShares) =>
      parseFloat((pastShares - songwriterToBeDeleted.share).toFixed(2))
    );

    const newSongWriters = existingSongWriters;

    if (index > 0 && index < newSongWriters.length) {
      newSongWriters.splice(index, 1);
    }

    setCurrentComposition((previousState) => ({
      ...previousState,
      song_writers: newSongWriters,
    }));

    API.delete(
      ApiUri.COMPOSITION +
        "/" +
        currentComposition.id +
        ApiUri.SONG_WRITER +
        "/" +
        songwriterToBeDeleted.id
    ).then(
      (response) => {
        initSnackbarSuccess("Songwriter deleted");
        stopGlobalLoader();
        setActiveSongwriterIndex(-1);
      },
      (err) => {
        stopGlobalLoader();
        initSnackbarError(err);
      }
    );
  };

  return (
    <Stepper activeStep={activeSongwriterIndex} orientation="vertical">
      {currentComposition.song_writers.map(
        (songWriter: SongWriter, index: number) => (
          <Step key={index}>
            <Box
              sx={{
                cursor: "pointer",
                ":hover": { textDecoration: "underline" },
              }}
              onClick={() => openSongwriter(index)}
            >
              <StepLabel
                icon={[
                  <Edit sx={{ cursor: "pointer" }} />,
                  <AssignmentInd sx={{ cursor: "pointer" }} />,
                ]}
              >
                <Typography
                  variant="subtitle1"
                  sx={{
                    cursor: "pointer",
                  }}
                >
                  {!songWriter.profile
                    ? songWriter.external_song_writer.first_name +
                      " " +
                      songWriter.external_song_writer.last_name +
                      " (External songwriter)"
                    : songWriter.profile.profile_information.name}
                </Typography>
              </StepLabel>
            </Box>

            <StepContent>
              <Grid container>
                <Grid item xs={12}>
                  <SongwriterForm
                    key={index}
                    songWriter={songWriter}
                    saveSongwriterCallback={saveSongwriterCallback}
                    showLyricsContribution={!currentComposition.is_instrumental}
                    index={index}
                    deleteSongwriterCallback={deleteSongwriterCallback}
                    currentComposition={currentComposition}
                  />
                </Grid>
              </Grid>
            </StepContent>
          </Step>
        )
      )}
    </Stepper>
  );
};

export default SongwritersStepper;
