import { Card, CircularProgress, Stack, Tooltip } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import ActionButtonSmall from "../../../components/layout/buttons/action-button-small";
import { GlobalLoaderContext } from "../../../context/global-loader";
import { SnackbarContext } from "../../../context/snackbar";
import { AuthContext } from "../../../context/auth";
import API from "../../../api/api";
import ConfirmProductModal from "./ConfirmProductModal";
import { Info } from "@mui/icons-material";

export const roundDownToSecondDecimal = (number) => {
  return number.toFixed(2);
};

const ProductCard = ({
  product,
  paymentGateways,
  available = true,
  currentSubscription = undefined,
}) => {
  const { initSnackbarError } = useContext(SnackbarContext);
  const { user } = useContext(AuthContext);

  const [loading, setLoading] = useState(true);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [imageURL, setImageURL] = useState("");

  const subProfileLimit = Number(product?.subprofile_limit) ?? 0;
  const subProfilesCount =
    Number(user?.last_accessed_profile?.sub_profiles_count) ?? 0;

  const subprofileLimitTooLow =
    // currentSubscription &&
    subProfileLimit && subProfilesCount && subProfilesCount > subProfileLimit;

  const periodsDontMatch =
    currentSubscription &&
    ((currentSubscription as any)?.package?.is_monthly !==
      product?.is_monthly ||
      (currentSubscription as any)?.package?.is_annual !== product?.is_annual);

  useEffect(() => {
    if (product.file) {
      API.get("/media-file" + "/" + product.file.id).then(
        (response) => {
          setImageURL(response.data.url);
          setLoading(false);
        },
        (err) => {
          initSnackbarError(err);
          setLoading(false);
        }
      );
      setLoading(false);
    } else {
      setLoading(false);
    }
  }, []);

  return (
    <Card variant="outlined" sx={{ padding: 2, height: "100%" }}>
      <Stack spacing={2} height={"100%"} sx={{ flexGrow: 1 }}>
        {loading ? (
          <CircularProgress color="inherit" size={20} />
        ) : (
          <React.Fragment>
            <img
              src={imageURL || ""}
              alt=""
              width="100%"
              style={{
                width: "100%",
                height: "150px",
                objectFit: "scale-down",
              }}
            />
            <h3>{product.name}</h3>
            <h2 style={{ paddingBottom: "10px" }}>
              £{" "}
              {roundDownToSecondDecimal(
                (product.discount ? 1 - product.discount : 1) * product.price
              )}
            </h2>
            <div style={{ marginTop: "auto" }}>
              <Tooltip
                title={
                  subprofileLimitTooLow
                    ? `This subscription allows a maximum of ${subProfileLimit} songwriter(s). Currently, there are ${subProfilesCount} active songwriters under your publisher profile. Please close ${
                        subProfilesCount - subProfileLimit
                      } in order to downgrade`
                    : periodsDontMatch
                    ? "Changing subscription from monthly to yearly (or vice versa) requires cancellation of your current subscription."
                    : null
                }
              >
                <span>
                  <ActionButtonSmall
                    onClick={() => setConfirmModalOpen(true)}
                    disabled={
                      !available || subprofileLimitTooLow || periodsDontMatch
                    }
                    endIcon={
                      subprofileLimitTooLow || periodsDontMatch ? (
                        <Info />
                      ) : null
                    }
                  >
                    {!available || subprofileLimitTooLow || periodsDontMatch
                      ? "Unavailable"
                      : "Select"}
                  </ActionButtonSmall>
                </span>
              </Tooltip>
            </div>
          </React.Fragment>
        )}
      </Stack>
      <ConfirmProductModal
        modalOpened={confirmModalOpen}
        handleClose={() => setConfirmModalOpen(false)}
        product={product}
        paymentGateways={paymentGateways}
        imageURL={imageURL}
        currentSubscription={currentSubscription}
      />
    </Card>
  );
};

export default ProductCard;
