import React, { useCallback, useContext, useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Loader from "../../../../components/loader";
import API from "../../../../api/api";
import { AuthContext, IAuthContext } from "../../../../context/auth";
import ApiUri from "../../../../api/api-uri";
import {
  Avatar,
  debounce,
  Grid,
  InputAdornment,
  Stack,
  TableSortLabel,
  TextField,
} from "@mui/material";
import { Search } from "@mui/icons-material";
import Filters from "./filters";
import { Song } from "../../../../models/song/song";
import Colors from "../../../../enums/colors";
import ContextMenu from "./context-menu";
import AboutSongModal from "../about-song-modal/index";
import PaginationActions from "../../../../components/utilities/pagination-actions";
import Compositions from "../../../../enums/compositions";
import { Profile } from "../../../../models/profile/profile";
import RoleTypes from "../../../../enums/role-types";
import ProfileTypes from "../../../../enums/profile-types";
import { SnackbarContext } from "../../../../context/snackbar";
import ConfirmationDialogActivator from "../../../../components/confirmation-dialog/confirmation-dialog-activator";
import { GlobalLoaderContext } from "../../../../context/global-loader";

export default function SongsTable() {
  const { user } = useContext<IAuthContext>(AuthContext);
  const { initSnackbarGeneric, initSnackbarSuccess, initSnackbarError } =
    useContext(SnackbarContext);
  const { startGlobalLoader, stopGlobalLoader } =
    useContext(GlobalLoaderContext);
  const [songs, setSongs] = useState<Song[] | null>(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [totalRows, setTotalRows] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [status, setStatus] = useState<string | null>(
    user?.last_accessed_profile.profile_type.name === ProfileTypes.SERVICE
      ? Compositions.STATUS_SUBMITTED
      : null
  );
  const [modalOpened, setModalOpened] = useState(false);
  const [selectedSong, setSelectedSong] = useState<Song>();
  const [aboutSubProfileModalOpened, setAboutSubProfileModalOpened] =
    useState(false);
  const [selectedProfileId, setSelectedProfileId] = useState<Profile>();
  const [hideContextMenu, setHideContextMenu] = useState(false);
  const [orderDescending, setOrderDescending] = useState(true);

  useEffect(() => {
    let isMounted = true;
    API.get(ApiUri.PROFILE + "/" + user?.last_accessed_profile.id).then(
      (response) => {
        const profileObject = response.data;
        const currentUserRole = profileObject?.profile_rbac.find(
          (profile) => profile.user.id === user?.id
        )?.role.name;

        if (
          currentUserRole !== RoleTypes.OWNER &&
          currentUserRole !== RoleTypes.ADMIN &&
          currentUserRole !== RoleTypes.EDITOR &&
          !user?.is_mmpz_service_admin
        ) {
          setHideContextMenu(true);
        }
      }
    );
    if (songs) {
      return;
    }

    API.get(
      ApiUri.PROFILE + "/" + user?.last_accessed_profile.id + ApiUri.SONG,
      {
        params: {
          limit: rowsPerPage,
          page: page + 1,
          search_term: searchTerm.trim(),
          status: status,

          sort:
            status === Compositions.STATUS_SUBMITTED
              ? "submitted_at"
              : status === Compositions.STATUS_PUBLISHED
              ? "published_at"
              : "",
          sort_order: orderDescending ? "desc" : "asc",
        },
      }
    ).then(
      (response) => {
        if (isMounted) {
          setTotalRows(response.data.meta.total);
          setSongs(response.data.collection);
        }
      },
      (error) => {
        setTotalRows(0);
        setSongs([]);
        initSnackbarError(error);
      }
    );

    return () => {
      isMounted = false;
    };
  }, [songs]); // eslint-disable-line

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
    setSongs(null);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(event.target.value);
    setPage(0);
    setSongs(null);
  };

  const debouncedSearchTermInput = useCallback(
    debounce(() => {
      setPage(0);
      setSongs(null);
    }, 800),
    []
  );

  const handleChangeSearchBy = (event) => {
    setSearchTerm(event.target.value);
    debouncedSearchTermInput();
  };

  const handleChangeStatus = (newFilterStatus) => {
    setStatus(newFilterStatus);
    setOrderDescending(true);
    setPage(0);
    setSongs(null);
  };

  const handleRowCellClick = (song: Song) => {
    setSelectedSong(song);
    setModalOpened(true);
  };

  const handleModalClose = () => {
    setModalOpened(false);
  };

  const formatDate = (date) => {
    if (date) {
      const parsedDate = new Date(date);

      const day = String(parsedDate.getDate()).padStart(2, "0");
      const month = String(parsedDate.getMonth() + 1).padStart(2, "0"); // Note: Month is 0-based, so we add 1.
      const year = parsedDate.getFullYear();
      const hours = String(parsedDate.getHours()).padStart(2, "0");
      const minutes = String(parsedDate.getMinutes()).padStart(2, "0");

      const formattedDateTime = `${day}/${month}/${year} ${hours}:${minutes}`;
      return formattedDateTime;
    } else return;
  };

  const handleChangeSortOrder = () => {
    setOrderDescending((oldValue) => !oldValue);
    setSongs(null);
  };

  const handleCopy = (email) => {
    navigator.clipboard.writeText(email);
    initSnackbarGeneric("Email copied to clipboard.");
  };

  const showTakedownAllSongsButton =
    user?.is_mmpz_service_admin &&
    (user?.last_accessed_profile?.profile_type?.name ===
      ProfileTypes.SONGWRITER ||
      user?.last_accessed_profile?.profile_type?.name ===
        ProfileTypes.PUBLISHER);

  const handleTakedownAllSongs = () => {
    startGlobalLoader();

    const formData = { status: "taken_down" };

    API.put(
      ApiUri.PROFILE +
        "/" +
        user?.last_accessed_profile?.id +
        ApiUri.COMPOSITION_STATUS_CHANGE,
      formData
    ).then(
      (response) => {
        stopGlobalLoader();
        initSnackbarSuccess("All songs successfully taken down");
        setSongs(null);
      },
      (error) => {
        stopGlobalLoader();
        initSnackbarError(error);
      }
    );
  };

  return (
    <React.Fragment>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        spacing={2}
        style={{ marginBottom: 5 }}
      >
        {showTakedownAllSongsButton && (
          <Grid item xs={12}>
            <ConfirmationDialogActivator
              onClick={handleTakedownAllSongs}
              actionName="Takedown all published songs"
              content="Are you sure you want to takedown all published songs for this profile?"
            />
          </Grid>
        )}
        <Grid item>
          <Filters
            status={status}
            changeStatus={handleChangeStatus}
            songsHaveLoaded={songs}
          />
        </Grid>
        <Grid item>
          <TextField
            label="Search"
            focused={true}
            value={searchTerm}
            onChange={handleChangeSearchBy}
            variant="outlined"
            size="small"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>

      <Paper elevation={3}>
        <TableContainer>
          <Table>
            <TableHead style={{ borderBottom: "2px solid" }}>
              <TableRow>
                {status === Compositions.STATUS_SUBMITTED && (
                  <TableCell>
                    <TableSortLabel
                      active
                      direction={orderDescending ? "desc" : "asc"}
                      onClick={handleChangeSortOrder}
                    >
                      <b>Date Submitted</b>
                    </TableSortLabel>
                  </TableCell>
                )}
                {status === Compositions.STATUS_PUBLISHED && (
                  <TableCell>
                    <TableSortLabel
                      active
                      direction={orderDescending ? "desc" : "asc"}
                      onClick={handleChangeSortOrder}
                    >
                      <b>Date Published</b>
                    </TableSortLabel>
                  </TableCell>
                )}
                <TableCell>
                  <b>Song Title</b>
                </TableCell>
                <TableCell>
                  <b>Songwriter(s)</b>
                </TableCell>
                <TableCell>
                  <b>Release Name</b>
                </TableCell>
                <TableCell>
                  <b>Status</b>
                </TableCell>
                <TableCell>
                  <b>ISRC</b>
                </TableCell>
                <TableCell>
                  <b>ISWC</b>
                </TableCell>
                <TableCell>
                  <b>Artist Name</b>
                </TableCell>
                <TableCell>
                  <b>Profile</b>
                </TableCell>
                <TableCell>
                  <b>Created By</b>
                </TableCell>
                {(status === Compositions.STATUS_PROCESSING ||
                  status === Compositions.STATUS_PUBLISHED) && (
                  <TableCell>
                    <b>Set By</b>
                  </TableCell>
                )}
                <TableCell>
                  <b>Publisher Administrator</b>
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody sx={{ cursor: "pointer" }}>
              {songs ? (
                (songs as any).map((row: Song) => {
                  return (
                    <TableRow hover key={row.id}>
                      {status === Compositions.STATUS_PUBLISHED && (
                        <TableCell onClick={() => handleRowCellClick(row)}>
                          {formatDate(row?.composition?.published_at) ?? "-"}
                        </TableCell>
                      )}
                      {status === Compositions.STATUS_SUBMITTED && (
                        <TableCell onClick={() => handleRowCellClick(row)}>
                          {formatDate(row?.composition?.submitted_at) ?? "-"}
                        </TableCell>
                      )}
                      <TableCell onClick={() => handleRowCellClick(row)}>
                        {row.composition.title}
                      </TableCell>
                      <TableCell onClick={() => handleRowCellClick(row)}>
                        {row.composition.songwriter_names.map((name, index) => (
                          <React.Fragment key={index}>
                            <span>{name}</span>
                            <br></br>
                          </React.Fragment>
                        ))}
                      </TableCell>
                      <TableCell onClick={() => handleRowCellClick(row)}>
                        {row.release_name}
                      </TableCell>
                      <TableCell onClick={() => handleRowCellClick(row)}>
                        <Grid container spacing={1}>
                          <Grid item>
                            <Avatar
                              style={{
                                width: 25,
                                height: 25,
                                backgroundColor:
                                  row.composition.status ===
                                  Compositions.STATUS_DRAFT
                                    ? Colors.cherry
                                    : Colors.grey,
                              }}
                            >
                              1
                            </Avatar>
                          </Grid>
                          <Grid item>
                            <Avatar
                              style={{
                                width: 25,
                                height: 25,
                                backgroundColor:
                                  row.composition.status ===
                                  Compositions.STATUS_SUBMITTED
                                    ? Colors.bordeaux
                                    : Colors.grey,
                              }}
                            >
                              2
                            </Avatar>
                          </Grid>
                          <Grid item>
                            <Avatar
                              style={{
                                width: 25,
                                height: 25,
                                backgroundColor:
                                  row.composition.status ===
                                  Compositions.STATUS_PROCESSING
                                    ? Colors.crimson
                                    : Colors.grey,
                              }}
                            >
                              3
                            </Avatar>
                          </Grid>
                          <Grid item>
                            <Avatar
                              style={{
                                width: 25,
                                height: 25,
                                backgroundColor:
                                  row.composition.status ===
                                  Compositions.STATUS_PUBLISHED
                                    ? Colors.flamingo
                                    : Colors.grey,
                              }}
                            >
                              4
                            </Avatar>
                          </Grid>
                          <Grid item>
                            <Avatar
                              style={{
                                width: 25,
                                height: 25,
                                backgroundColor:
                                  row.composition.status ===
                                  Compositions.STATUS_TAKEN_DOWN
                                    ? Colors.wine
                                    : Colors.grey,
                              }}
                            >
                              5
                            </Avatar>
                          </Grid>
                        </Grid>
                      </TableCell>
                      <TableCell onClick={() => handleRowCellClick(row)}>
                        {row.isrc}
                      </TableCell>
                      <TableCell onClick={() => handleRowCellClick(row)}>
                        {row.composition.iswc}
                      </TableCell>
                      <TableCell onClick={() => handleRowCellClick(row)}>
                        {row.artists
                          ? row.artists.map((item) => item.name).join(", ")
                          : ""}
                      </TableCell>
                      <TableCell onClick={() => handleRowCellClick(row)}>
                        {
                          row.composition?.profileOwner?.profile_information
                            ?.name
                        }
                      </TableCell>
                      <TableCell
                        onClick={() =>
                          user?.is_mmpz_service_admin
                            ? handleCopy(row?.composition?.created_by?.email)
                            : handleRowCellClick(row)
                        }
                      >
                        {row.composition?.created_by?.user_profile?.first_name +
                          " " +
                          row.composition?.created_by?.user_profile?.last_name}
                      </TableCell>
                      {(status === Compositions.STATUS_PROCESSING ||
                        status === Compositions.STATUS_PUBLISHED) && (
                        <TableCell onClick={() => handleRowCellClick(row)}>
                          {status === Compositions.STATUS_PROCESSING
                            ? row.composition?.processed_by?.user_profile
                                ?.first_name &&
                              row.composition?.processed_by?.user_profile
                                ?.last_name
                              ? row.composition?.processed_by?.user_profile
                                  ?.first_name +
                                " " +
                                row.composition?.processed_by?.user_profile
                                  ?.last_name
                              : "-"
                            : row.composition?.published_by?.user_profile
                                ?.first_name &&
                              row.composition?.published_by?.user_profile
                                ?.last_name
                            ? row.composition?.published_by?.user_profile
                                ?.first_name +
                              " " +
                              row.composition?.published_by?.user_profile
                                ?.last_name
                            : "-"}
                        </TableCell>
                      )}
                      <TableCell onClick={() => handleRowCellClick(row)}>
                        {row.composition?.publisherAdministratorProfileOwner
                          ?.profile_information?.name ??
                          row.composition?.parentProfileOwner
                            ?.profile_information?.name}
                      </TableCell>
                      {!hideContextMenu && (
                        <TableCell>
                          <ContextMenu song={row} setSongs={setSongs} />
                        </TableCell>
                      )}
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell>
                    <Stack direction="row" justifyContent="center">
                      <Loader message="Loading songs" />
                    </Stack>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[5, 10, 15, 20, 50, 100]}
          component="div"
          count={totalRows}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          ActionsComponent={PaginationActions}
        />
      </Paper>

      <AboutSongModal
        modalOpened={modalOpened}
        handleClose={handleModalClose}
        compositionId={selectedSong?.composition.id}
        disableEdit={hideContextMenu}
        song={selectedSong}
      />
    </React.Fragment>
  );
}
