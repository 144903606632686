import React, { useContext, useEffect, useState } from "react";
import { GlobalLoaderContext } from "../../../context/global-loader";
import { SnackbarContext } from "../../../context/snackbar";
import { AuthContext } from "../../../context/auth";
import API from "../../../api/api";
import ApiUri from "../../../api/api-uri";
import ActiveSubscriptionsTable from "./ActiveSubscriptionsTable";
import { Grid, Stack } from "@mui/material";
import ActionButtonSmall from "../../../components/layout/buttons/action-button-small";
import PaymentHistoryModal from "./PaymentHistoryModal";
import ProductCard from "../../songs/add-new-song/ProductCard";
import ProfileTypes from "../../../enums/profile-types";
import RoleTypes from "../../../enums/role-types";
import { useHistory } from "react-router-dom";
import { getCompleteProfile } from "../../../api/utils/get/getCompleteProfile";
import { getProduct } from "../../../api/utils/get/getProduct";
import { getAllProducts } from "../../../api/utils/get/getAllProducts";
import { getPaymentGateways } from "../../../api/utils/get/getPaymentGateways";
import ManualInvoiceModal from "../../songs/add-new-song/ManualInvoiceModal";

const ManageSubscriptionsSongwriter = () => {
  const { user, userRole } = useContext(AuthContext);
  const { startGlobalLoader, stopGlobalLoader } =
    useContext(GlobalLoaderContext);
  const { initSnackbarError, initSnackbarSuccess, initSnackbarGeneric } =
    useContext(SnackbarContext);
  const history = useHistory();

  const [activeSubscriptions, setActiveSubscriptions] = useState<any>([]);
  const [inactiveSubscriptions, setInactiveSubscriptions] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [productsAvailableForPurchase, setProductsAvailableForPurchase] =
    useState([]);
  const [paymentGateways, setPaymentGateways] = useState<any>(null);
  const [showContactPublisherMessage, setShowContactPublisherMessage] =
    useState(false);
  const [activeSubHasParent, setActiveSubHasParent] = useState(false);
  const [
    showManageSubscriptionsSongwriterPage,
    setShowManageSubscriptionsSongwriterPage,
  ] = useState(false);
  const [openManualInvoiceModal, setOpenManualInvoiceModal] = useState(false);

  const seeThisPage =
    ((userRole === RoleTypes.OWNER ||
      userRole === RoleTypes.ADMIN ||
      user?.is_mmpz_service_admin) &&
      user?.last_accessed_profile.profile_type.name ===
        ProfileTypes.SONGWRITER) ||
    user?.last_accessed_profile.profile_type.name === ProfileTypes.PUBLISHER;

  const getActiveSubscriptions = (products) => {
    const activeSubscriptions = products.filter((product) => {
      return (
        product.active &&
        product.package.type === "Subscription" &&
        product.package.profile_type.name ===
          user?.last_accessed_profile.profile_type.name
      );
    });
    return activeSubscriptions;
  };

  const getInactiveSubscriptions = (products) => {
    const inactiveSubscriptions = products.filter((product) => {
      return (
        !product.active &&
        product.package.type === "Subscription" &&
        product.package.profile_type.name ===
          user?.last_accessed_profile.profile_type.name
      );
    });
    return inactiveSubscriptions;
  };

  useEffect(() => {
    if (!seeThisPage) {
      initSnackbarError("You don't have permission to access this page.");
      history.push("/dashboard");
      return;
    } else {
      setShowManageSubscriptionsSongwriterPage(true);
      const processSubs = async () => {
        startGlobalLoader();
        setLoading(true);
        try {
          const completeProfileRespose = await getCompleteProfile({
            profileId: user?.last_accessed_profile.id,
          });
          const profileObject = completeProfileRespose.data;
          const currentUserRole = profileObject.profile_rbac.find(
            (profile) => profile.user.id === user?.id
          )?.role.name;
          if (
            currentUserRole !== RoleTypes.OWNER &&
            currentUserRole !== RoleTypes.ADMIN &&
            !user?.is_mmpz_service_admin
          ) {
            initSnackbarError("You don't have permission to access this page.");
            history.push("/dashboard");
            return;
          }
          const parentProfileId = profileObject.parent.id;
          const allProductsFromProfile = profileObject.package_song_writers;

          const parentProfileResponse = await getCompleteProfile({
            profileId: parentProfileId,
          });
          const parentProfile = parentProfileResponse.data;
          const parentProfileType = parentProfile.profile_type.name;
          if (parentProfileType === ProfileTypes.PUBLISHER) {
            setShowContactPublisherMessage(true);
          } else {
            const activeSubs = getActiveSubscriptions(allProductsFromProfile);

            if (activeSubs.length > 0) {
              const completeSubResponse = await getProduct({
                productId: activeSubs[0]?.package.id,
              });
              const completeSub = completeSubResponse.data;
              if (completeSub.parent) {
                activeSubs[0].package.name = completeSub.parent.name;
                activeSubs[0].hasParent = true;
                setActiveSubHasParent(true);
              }
            }
            setActiveSubscriptions(activeSubs);

            const inactiveSubs = getInactiveSubscriptions(
              allProductsFromProfile
            );
            setInactiveSubscriptions(inactiveSubs);

            const allProductsResponse = await getAllProducts();
            let allProducts = allProductsResponse.data.collection;
            // uncomment this when basic subs are live
            if (allProductsFromProfile.length === 0) {
              allProducts = allProducts.filter((product) => product.is_basic);
            }
            const filteredProducts = allProducts.filter(
              (product) =>
                product.profile_type.name ===
                user?.last_accessed_profile.profile_type.name
            );
            setProductsAvailableForPurchase(filteredProducts);
            const listOfPaymentGatewaysResponse = await getPaymentGateways({
              profileId: profileObject.parent.id,
            });
            const listOfPaymentGateways =
              listOfPaymentGatewaysResponse.data.collection;
            const listOfActivePaymentGateways = listOfPaymentGateways.filter(
              (paymentGateway) => paymentGateway.status
            );
            setPaymentGateways(listOfActivePaymentGateways);
          }
        } catch (err) {
          initSnackbarError(err);
        } finally {
          stopGlobalLoader();
          setLoading(false);
        }
      };
      processSubs();
    }
  }, []);

  useEffect(() => {
    if (!activeSubscriptions) {
      const reloadSubs = async () => {
        startGlobalLoader();
        setLoading(true);
        try {
          const completeProfileResponse = await getCompleteProfile({
            profileId: user?.last_accessed_profile.id,
          });
          const allProducts = completeProfileResponse.data.package_song_writers;
          const activeSubs = getActiveSubscriptions(allProducts);
          if (activeSubs.length > 0) {
            const completeSubResponse = await getProduct({
              productId: activeSubs[0]?.package.id,
            });
            const completeSub = completeSubResponse.data;
            if (completeSub.parent) {
              activeSubs[0].package.name = completeSub.parent.name;
              activeSubs[0].hasParent = true;
              setActiveSubHasParent(true);
            }
          }
          setActiveSubscriptions(activeSubs);
          const inactiveSubs = getInactiveSubscriptions(allProducts);
          setActiveSubscriptions(activeSubs);
          setInactiveSubscriptions(inactiveSubs);
        } catch (err) {
          initSnackbarError(err);
        } finally {
          stopGlobalLoader();
          setLoading(false);
        }
      };
      reloadSubs();
    }
  }, [activeSubscriptions]);

  const handleClick = () => {
    setShowModal(true);
  };

  const handleReactivateClick = () => {
    setOpenManualInvoiceModal(true);
  };

  return (
    showManageSubscriptionsSongwriterPage && (
      <React.Fragment>
        {!loading && (
          <>
            {showContactPublisherMessage ? (
              <p>Please contact your Publisher</p>
            ) : (
              <>
                <Stack spacing={5}>
                  {activeSubscriptions?.length > 0 ? (
                    <Stack>
                      <p>Your current Subscription:</p>
                      <ActiveSubscriptionsTable
                        subscriptions={activeSubscriptions}
                        setSubscriptions={setActiveSubscriptions}
                      />
                    </Stack>
                  ) : (
                    !loading && (
                      <Stack>
                        <p>You don't have any active subscriptions</p>
                      </Stack>
                    )
                  )}
                  <Stack direction={"row"} spacing={3}>
                    <ActionButtonSmall onClick={handleClick}>
                      Payment History
                    </ActionButtonSmall>
                    {user?.is_mmpz_service_admin &&
                      activeSubscriptions?.length === 0 &&
                      inactiveSubscriptions.length > 0 &&
                      inactiveSubscriptions[inactiveSubscriptions.length - 1]
                        ?.status === "cancelled" && (
                        <ActionButtonSmall onClick={handleReactivateClick}>
                          Reactivate Last Subscription
                        </ActionButtonSmall>
                      )}
                  </Stack>
                  <p>Subscriptions available for you:</p>
                  {productsAvailableForPurchase.length > 0 &&
                  paymentGateways &&
                  paymentGateways.length > 0 ? (
                    <Grid container spacing={2}>
                      {productsAvailableForPurchase
                        .filter((product: any) => product.enabled)
                        .filter(
                          (product: any) =>
                            product.visible ||
                            (product.visible_to_admin_only &&
                              user?.is_mmpz_service_admin)
                        )
                        .filter(
                          (product: any) => product.type === "Subscription"
                        )
                        .filter((product: any) => !product.parent)
                        .map((product: any) => {
                          return (
                            <Grid item key={product.id} xs={6} md={3}>
                              <ProductCard
                                product={product}
                                paymentGateways={paymentGateways}
                                currentSubscription={
                                  activeSubscriptions
                                    ? activeSubscriptions[0]
                                    : undefined
                                }
                                available={
                                  activeSubscriptions[0]?.package?.package_plus
                                    ?.id === product.id ||
                                  activeSubscriptions[0]?.package
                                    ?.package_standard?.id === product.id
                                    ? true
                                    : user?.last_accessed_profile.profile_type
                                        .name === ProfileTypes.PUBLISHER
                                    ? activeSubscriptions?.length === 0
                                      ? true
                                      : activeSubHasParent
                                      ? activeSubscriptions[0]?.package?.parent
                                          ?.package_plus?.id === product.id ||
                                        activeSubscriptions[0]?.package?.parent
                                          ?.package_standard?.id ===
                                          product.id ||
                                        (product.id !==
                                          activeSubscriptions[0]?.package.id &&
                                          product.name !==
                                            activeSubscriptions[0]?.package
                                              .name)
                                      : product.name !==
                                        activeSubscriptions[0]?.package.name
                                    : activeSubscriptions[0]?.package?.parent
                                        ?.package_plus?.id === product.id ||
                                      activeSubscriptions[0]?.package?.parent
                                        ?.package_standard?.id === product.id ||
                                      activeSubscriptions?.length === 0
                                }
                              />
                            </Grid>
                          );
                        })}
                    </Grid>
                  ) : (
                    "-"
                  )}
                </Stack>
                {showModal && (
                  <PaymentHistoryModal
                    modalOpened={showModal}
                    handleClose={() => setShowModal(false)}
                    activeSubscriptions={activeSubscriptions}
                    inactiveSubscriptions={inactiveSubscriptions}
                  />
                )}
                <ManualInvoiceModal
                  modalOpened={openManualInvoiceModal}
                  handleClose={() => setOpenManualInvoiceModal(false)}
                  subscription={
                    inactiveSubscriptions[inactiveSubscriptions.length - 1]
                  }
                  paymentGateway={paymentGateways ? paymentGateways[0] : ""}
                  reactivate={true}
                />
              </>
            )}
          </>
        )}
      </React.Fragment>
    )
  );
};

export default ManageSubscriptionsSongwriter;
