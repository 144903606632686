import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../context/auth";
import ProfileTypes from "../../../enums/profile-types";
import RoleTypes from "../../../enums/role-types";
import { SnackbarContext } from "../../../context/snackbar";
import { useHistory } from "react-router-dom";
import ImportRoyaltyStatementForm from "./ImportRoyaltyStatementForm";

const ImportRoyaltyStatement = () => {
  const { user, userRole } = useContext(AuthContext);
  const { initSnackbarError } = useContext(SnackbarContext);
  const history = useHistory();

  const [showPage, setShowPage] = useState(false);

  const seeThisPage =
    (user?.last_accessed_profile.profile_type.name ===
      ProfileTypes.PUBLISHER_ADMINISTRATOR ||
      user?.last_accessed_profile.profile_type.name === ProfileTypes.SERVICE) &&
    (userRole === RoleTypes.ADMIN ||
      userRole === RoleTypes.OWNER ||
      user?.is_mmpz_service_admin);

  useEffect(() => {
    if (!seeThisPage) {
      initSnackbarError("You don't have permission to access this page.");
      history.push("/dashboard");
      return;
    } else {
      setShowPage(true);
    }
  }, []);

  return showPage && <ImportRoyaltyStatementForm />;
};

export default ImportRoyaltyStatement;
