import { Grid, Paper } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import ContributionsCheckboxes from "./ContributionsCheckboxes";
import SmartCaeNumberField from "./SmartCaeNumberField";
import ExistingSongwriterConfirmationDialogActivator from "./ExistingSongwriterConfirmationDialogActivator";
import ExternalSongwriterInfo from "./ExternalSongwriterInfo";
import PublisherInfoFields from "./PublisherInfoFields";
import { SongWriter } from "../../../../../models/composition/song-writer";
import ProAfilitiationField from "./ProAfilitiationField";
import { ProAffiliation } from "../../../../../models/composition/pro-affiliation";
import API from "../../../../../api/api";
import ApiUri from "../../../../../api/api-uri";
import ShareField from "./ShareField";
import ActionButtonSmall from "../../../../../components/layout/buttons/action-button-small";
import { GlobalLoaderContext } from "../../../../../context/global-loader";
import { SnackbarContext } from "../../../../../context/snackbar";
import { Composition } from "../../../../../models/composition/composition";
import ExistingSongwriterInfo from "./ExistingSongwriterInfo";

export interface InitialSongwriter {
  id: any;
  share: any;
  songwriter_cae_number: string;
  publisher_cae_number: string;
  publisher_name: string;
  music_contribution: boolean;
  has_cae_number: boolean;
  lyrics_contribution: boolean;
  pro_affiliation: any;
  external_song_writer: {
    first_name: string;
    last_name: string;
  };
}

export const initialSongwriter: InitialSongwriter = {
  id: null,
  share: 0,
  songwriter_cae_number: "",
  publisher_cae_number: "",
  publisher_name: "",
  music_contribution: false,
  has_cae_number: true,
  lyrics_contribution: false,
  pro_affiliation: null,
  external_song_writer: {
    first_name: "",
    last_name: "",
  },
};

interface NewSongWriterFormProps {
  showLyricsContribution: boolean;
  currentComposition: Composition;
  saveNewSongwriterCallback: (newSongwriter: SongWriter) => void;
}

const NewSongwriterForm: React.FC<NewSongWriterFormProps> = ({
  showLyricsContribution,
  currentComposition,
  saveNewSongwriterCallback,
}) => {
  const [newSongwriter, setNewSongwriter] = useState(initialSongwriter);
  const [caeNumberIsBeingEdited, setCaeNumberIsBeingEdited] = useState(false);
  const [existingSongwriter, setExistingSongwriter] = useState(null as any);
  const [usingExistingSongwriter, setUsingExistingSongwriter] = useState(false);
  const [proAffiliations, setProAffiliations] = useState<Array<ProAffiliation>>(
    []
  );

  const { startGlobalLoader, stopGlobalLoader } =
    useContext(GlobalLoaderContext);
  const { initSnackbarError } = useContext(SnackbarContext);

  useEffect(() => {
    let isMounted = true;

    API.get(ApiUri.PRO_AFFILIATION)
      .then((response) => {
        if (isMounted) {
          const unsortedArray = response.data.collection;
          const sortedArray = unsortedArray.sort((a, b) => {
            if (a.name_code === "NS") return -1; // "NS" comes first
            if (b.name_code === "NS") return 1; // "NS" comes first

            // For other cases, sort alphabetically based on name_code
            return a.name_code.localeCompare(b.name_code);
          });

          setProAffiliations(sortedArray);
        }
      })
      .catch((error) => {
        if (isMounted) {
          initSnackbarError(error);
        }
      });

    return () => {
      isMounted = false;
    };
  }, []);

  const handleSaveClick = () => {
    startGlobalLoader();
    const shareString = newSongwriter.share;
    const convertedShare = parseFloat(parseFloat(shareString).toFixed(2));
    newSongwriter.share = convertedShare;
    API.post(
      ApiUri.COMPOSITION + "/" + currentComposition.id + ApiUri.SONG_WRITER,
      newSongwriter
    ).then(
      (response) => {
        saveNewSongwriterCallback({
          ...response.data,

          profile: existingSongwriter
            ? {
                id: existingSongwriter.id,
                status: existingSongwriter.status,
                profile_information: {
                  id: existingSongwriter.id,
                  name: existingSongwriter.profile_information.name,
                },
                cae_number: existingSongwriter.cae_number,
              }
            : null,
        });
        stopGlobalLoader();
      },
      (err) => {
        setUsingExistingSongwriter(false);
        setExistingSongwriter(null);
        setNewSongwriter(initialSongwriter);
        initSnackbarError(err);
        stopGlobalLoader();
      }
    );
  };

  // change first case
  const isFormValid = usingExistingSongwriter
    ? (newSongwriter.music_contribution || newSongwriter.lyrics_contribution) &&
      (newSongwriter.pro_affiliation || !newSongwriter.has_cae_number) &&
      (newSongwriter.songwriter_cae_number || !newSongwriter.has_cae_number) &&
      newSongwriter.publisher_cae_number &&
      newSongwriter.share &&
      // newSongwriter.publisher_name &&
      !caeNumberIsBeingEdited
    : (newSongwriter.music_contribution || newSongwriter.lyrics_contribution) &&
      (newSongwriter.pro_affiliation || !newSongwriter.has_cae_number) &&
      (newSongwriter.songwriter_cae_number || !newSongwriter.has_cae_number) &&
      // newSongwriter.publisher_cae_number &&
      newSongwriter.share &&
      // newSongwriter.publisher_name &&
      newSongwriter.external_song_writer?.first_name &&
      newSongwriter.external_song_writer?.last_name &&
      !caeNumberIsBeingEdited;

  return (
    <React.Fragment>
      <Paper elevation={6} sx={{ p: 4 }}>
        <Grid container spacing={4}>
          <ContributionsCheckboxes
            newSongwriter={newSongwriter}
            setNewSongwriter={setNewSongwriter}
            showLyricsContribution={showLyricsContribution}
          />
          <SmartCaeNumberField
            newSongwriter={newSongwriter}
            setNewSongwriter={setNewSongwriter}
            setCaeNumberIsBeingEdited={setCaeNumberIsBeingEdited}
            setExistingSongwriter={setExistingSongwriter}
            setUsingExistingSongwriter={setUsingExistingSongwriter}
            currentSongwriters={currentComposition.song_writers}
            usingExistingSongwriter={usingExistingSongwriter}
          />
          {usingExistingSongwriter ? (
            <ExistingSongwriterInfo newSongwriter={newSongwriter} />
          ) : (
            <ExternalSongwriterInfo
              newSongwriter={newSongwriter}
              setNewSongwriter={setNewSongwriter}
              usingExistingSongwriter={usingExistingSongwriter}
            />
          )}
          <PublisherInfoFields
            newSongwriter={newSongwriter}
            setNewSongwriter={setNewSongwriter}
            usingExistingSongwriter={usingExistingSongwriter}
          />
          <ProAfilitiationField
            newSongwriter={newSongwriter}
            setNewSongwriter={setNewSongwriter}
            proAffiliations={proAffiliations}
            currentComposition={currentComposition}
          />
          <ShareField
            newSongwriter={newSongwriter}
            setNewSongwriter={setNewSongwriter}
          />
          <Grid item xs={12}>
            <ActionButtonSmall
              disabled={!isFormValid}
              onClick={handleSaveClick}
            >
              Save new Songwriter
            </ActionButtonSmall>
          </Grid>
        </Grid>
      </Paper>
      <ExistingSongwriterConfirmationDialogActivator
        setUsingExistingSongwriter={setUsingExistingSongwriter}
        existingSongwriter={existingSongwriter}
        setNewSongwriter={setNewSongwriter}
        setExistingSongwriter={setExistingSongwriter}
      />
    </React.Fragment>
  );
};

export default NewSongwriterForm;
