import React, { useContext, useState, useEffect } from "react";
import { MessagesContext } from "../context/messages";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button,
  Divider,
  Box,
} from "@mui/material";
import axios from "axios";
import { Message } from "../models/message/notification";
import { AuthContext } from "../context/auth";
import ActionButtonSmall from "../components/layout/buttons/action-button-small";
import ApiUri from "../api/api-uri";
import API from "../api/api";
import { SnackbarContext } from "../context/snackbar";

const PopupMessages = () => {
  const { messages, setMessages } = useContext(MessagesContext);
  const { user } = useContext(AuthContext);
  const { initSnackbarError } = useContext(SnackbarContext);
  const [currentMessage, setCurrentMessage] = useState<Message | null>(null);

  useEffect(() => {
    const currentDate = new Date();
    const popupMessages = messages.filter(
      (message) =>
        message.popup &&
        !message.readers.find(
          (reader) => reader?.profile?.id === user?.last_accessed_profile.id
        ) &&
        currentDate >= new Date(message.visible_from) &&
        currentDate <= new Date(message.visible_until)
    );

    if (popupMessages.length > 0) {
      setCurrentMessage(popupMessages[0]);
    }
  }, [messages]);

  const handleOk = (message: Message) => {
    API.put(
      ApiUri.PROFILE +
        "/" +
        user?.last_accessed_profile?.id +
        ApiUri.MESSAGE +
        "/" +
        message?.id +
        ApiUri.READER
    ).then(
      (response) => {},
      (error) => {
        initSnackbarError(error);
      }
    );
    handleClose();
  };

  const handleClose = () => {
    if (currentMessage) {
      setMessages((prevMessages) =>
        prevMessages.map((msg) =>
          msg.id === currentMessage.id
            ? {
                ...msg,
                readers: [
                  ...(msg.readers || []),
                  { profile: { id: user?.last_accessed_profile.id } },
                ],
              }
            : msg
        )
      );
    }
    setCurrentMessage(null);
  };

  if (!currentMessage) return null;

  return (
    <Dialog
      open={Boolean(currentMessage)}
      onClose={handleClose}
      fullWidth
      sx={{
        zIndex: 1000000,
      }}
    >
      <DialogContent>
        <Box padding={2} display="flex" justifyContent="center">
          <Typography variant="h6" sx={{ textAlign: "center" }}>
            {currentMessage.content}
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center", p: 2 }}>
        <ActionButtonSmall
          color="primary"
          onClick={() => handleOk(currentMessage)}
        >
          OK
        </ActionButtonSmall>
      </DialogActions>
    </Dialog>
  );
};

export default PopupMessages;
