import React, { useContext, useEffect, useState } from "react";
import { GlobalLoaderContext } from "../../../context/global-loader";
import ApiUri from "../../../api/api-uri";
import API from "../../../api/api";
import { AuthContext } from "../../../context/auth";
import { SnackbarContext } from "../../../context/snackbar";
import { useHistory } from "react-router-dom";
import RoleTypes from "../../../enums/role-types";
import ProfileTypes from "../../../enums/profile-types";
import ImportedRoyaltyStatementsTable from "./ImportedRoyaltyStatementsTable";

const ManageImportedRoyaltyStatements = () => {
  const { startGlobalLoader, stopGlobalLoader } =
    useContext(GlobalLoaderContext);

  const [showManageSubProfilesPage, setShowManageSubProfilesPage] =
    useState(false);
  const { user, userRole } = useContext(AuthContext);
  const { initSnackbarError } = useContext(SnackbarContext);
  const history = useHistory();

  const seeThisPage =
    (userRole === RoleTypes.OWNER ||
      userRole === RoleTypes.ADMIN ||
      user?.is_mmpz_service_admin) &&
    (user?.last_accessed_profile.profile_type.name ===
      ProfileTypes.PUBLISHER_ADMINISTRATOR ||
      user?.last_accessed_profile.profile_type.name === ProfileTypes.SERVICE);

  useEffect(() => {
    if (!seeThisPage) {
      initSnackbarError("You don't have permission to access this page.");
      history.push("/dashboard");
      return;
    } else {
      setShowManageSubProfilesPage(true);
    }
  }, []);

  return (
    showManageSubProfilesPage && (
      <React.Fragment>
        <ImportedRoyaltyStatementsTable />
      </React.Fragment>
    )
  );
};

export default ManageImportedRoyaltyStatements;
