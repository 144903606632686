import { FileUpload } from "@mui/icons-material";
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Alert,
  AlertTitle,
  Stack,
  Link,
  Grid,
} from "@mui/material";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import React, { useContext, useState } from "react";
import ApiUri from "../../../api/api-uri";
import ActionButtonSmall from "../../../components/layout/buttons/action-button-small";
import { AuthContext } from "../../../context/auth";
import { GlobalLoaderContext } from "../../../context/global-loader";
import { SnackbarContext } from "../../../context/snackbar";
import API from "../../../api/api";
import dayjs from "dayjs"; // Import dayjs for date handling

const testSite =
  window.location.href.includes("test") ||
  window.location.href.includes("localhost");

const availablePlatforms = [
  { id: 2, name: "MCPS - Mechanical" },
  { id: 10, name: "MLC" },
  { id: 1, name: "PRS" },
];

const ImportRoyaltyStatementForm = ({
  reImport = false,
  existingStatement,
}: {
  reImport?: boolean;
  existingStatement?: any;
}) => {
  const [startDate, setStartDate] = useState(
    existingStatement?.date_from ? dayjs(existingStatement?.date_from) : null // Use dayjs
  );
  const [endDate, setEndDate] = useState(
    existingStatement?.date_to ? dayjs(existingStatement?.date_to) : null // Use dayjs
  );
  const [selectedPlatformID, setSelectedPlatformID] = useState(
    existingStatement?.platform?.id ?? ""
  );
  const [file, setFile] = useState<any>(null);
  const [successfullStatement, setSuccessfullStatement] = useState<any>();

  const { user } = useContext(AuthContext);
  const { startGlobalLoader, stopGlobalLoader } =
    useContext(GlobalLoaderContext);
  const { initSnackbarError } = useContext(SnackbarContext);

  const handleStartDateChange = (event) => {
    const newDate = event; // dayjs object
    setStartDate(newDate);
  };

  const handleEndDateChange = (event) => {
    const newDate = event; // dayjs object
    setEndDate(newDate);
  };

  const handlePlatformChange = (event) => {
    setSelectedPlatformID(event.target.value);
  };

  const handleUploadFile = function loadFile(event) {
    if (event.target.files.length > 0) {
      setFile(event.target.files[0]);
    }
  };

  const formatDate = (date) => {
    if (date) {
      return date.format("YYYY-MM-DD"); // Using dayjs format
    } else {
      return "";
    }
  };

  const handleSave = () => {
    startGlobalLoader();
    if (reImport && existingStatement) {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("reImportation", "yes");
      API.post(
        ApiUri.ROYALTY_IMPORTER_FILE +
          "/" +
          existingStatement?.id +
          ApiUri.FILE,
        formData
      ).then(
        (response) => {
          const completeStatement = response.data;
          setSuccessfullStatement(completeStatement);
          stopGlobalLoader();
        },
        (error) => {
          stopGlobalLoader();
          initSnackbarError(error);
        }
      );
    } else {
      const payloadObject = {
        date_from: formatDate(startDate),
        date_to: formatDate(endDate),
        platform: { id: selectedPlatformID },
      };
      API.post(
        ApiUri.PROFILE +
          "/" +
          user?.last_accessed_profile?.id +
          ApiUri.ROYALTY_IMPORTER_FILE,
        payloadObject
      ).then(
        (response) => {
          const statement = response.data;
          if (statement?.id) {
            const importRoyaltyStatementId = statement?.id;
            const formData = new FormData();
            formData.append("file", file);
            formData.append("reImportation", "no");
            API.post(
              ApiUri.ROYALTY_IMPORTER_FILE +
                "/" +
                importRoyaltyStatementId +
                ApiUri.FILE,
              formData
            ).then(
              (response) => {
                const completeStatement = response.data;
                setSuccessfullStatement(completeStatement);
                stopGlobalLoader();
              },
              (error) => {
                stopGlobalLoader();
                initSnackbarError(error);
              }
            );
          }
        },
        (error) => {
          stopGlobalLoader();
          initSnackbarError(error);
        }
      );
    }
  };

  const formIsValid = selectedPlatformID && startDate && endDate && file;

  return (
    <Grid paddingX={3} spacing={2} container>
      <Grid item container xs={12} spacing={2}>
        <Grid item xs={10}>
          <TextField
            value={file?.name ?? "-"}
            disabled
            label="Upload"
            fullWidth
          />
        </Grid>
        <Grid item xs={2}>
          <ActionButtonSmall
            component="label"
            sx={{ height: "100%" }}
            startIcon={<FileUpload />}
          >
            Find File
            <input
              hidden
              accept=".csv, .tsv, .txt"
              type="file"
              onChange={handleUploadFile}
            />
          </ActionButtonSmall>
        </Grid>
      </Grid>

      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Grid item container xs={12} spacing={2}>
          <Grid item xs={12} md={6}>
            <DatePicker
              label="Date From"
              value={startDate}
              onChange={handleStartDateChange}
              format="DD/MM/YYYY"
              disabled={reImport}
              sx={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <DatePicker
              label="Date To"
              value={endDate}
              onChange={handleEndDateChange}
              format="DD/MM/YYYY"
              disabled={reImport}
              sx={{ width: "100%" }}
              minDate={startDate}
            />
          </Grid>
        </Grid>
      </LocalizationProvider>

      <Grid item xs={12}>
        <FormControl fullWidth>
          <InputLabel>Platform</InputLabel>
          <Select
            value={selectedPlatformID}
            onChange={handlePlatformChange}
            label="Platform"
            disabled={reImport}
          >
            {availablePlatforms.map((platform) => (
              <MenuItem value={platform.id} key={platform.id}>
                {platform.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={12}>
        <ActionButtonSmall onClick={handleSave} disabled={!formIsValid}>
          Upload
        </ActionButtonSmall>
      </Grid>

      {successfullStatement && (
        <Grid item xs={12}>
          <Alert severity="success" sx={{ whiteSpace: "pre-line" }}>
            <AlertTitle>Royalty Statement Successfully Uploaded</AlertTitle>
            <Stack spacing={2}>
              {`Successful rows: ${successfullStatement?.imported_rows} \n Error rows: ${successfullStatement?.error_rows}`}
              <Link
                href={
                  `https://api.mmpz.${
                    testSite ? "test." : ""
                  }myclientzone.com/api/v1` +
                  ApiUri.FILE +
                  "/" +
                  successfullStatement?.error_file?.id
                }
              >
                Download Errors
              </Link>
            </Stack>
          </Alert>
        </Grid>
      )}
    </Grid>
  );
};

export default ImportRoyaltyStatementForm;
