import React, { useContext } from "react";
import { AuthContext } from "./context/auth";
import NavigationContextProvider from "./context/navigation";
import Breadcrumbs from "./layout/breadcrumbs";
import { Route } from "react-router-dom";
import BannerMessages from "./layout/banner-messages";
import { Stack } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import PopupMessages from "./layout/PopupMessages";

const testSite =
  window.location.href.includes("test") ||
  window.location.href.includes("localhost");

function PrivateRoute({ component: Component, ...rest }) {
  const { user } = useContext(AuthContext);

  const useStyles = makeStyles(function (theme) {
    return {
      content: {
        paddingTop: theme.spacing(6),
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        flexGrow: 1,
      },
    };
  });

  const classes = useStyles();

  return (
    <Route
      {...rest}
      render={(props: any) =>
        user ? (
          <React.Fragment>
            <BannerMessages />
            <PopupMessages />
            <Stack className={classes.content}>
              <NavigationContextProvider>
                <Breadcrumbs />
              </NavigationContextProvider>
              <Component {...props} />
            </Stack>
          </React.Fragment>
        ) : (
          window.location.replace(
            `https://mmaz.${testSite ? "test." : ""}myclientzone.com`
          )
        )
      }
    />
  );
}

export default PrivateRoute;
