import {
  AppBar,
  Dialog,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Slide,
  Stack,
  TextField,
  Theme,
  Typography,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import React, { useContext, useEffect, useState } from "react";
import CustomToolbar from "../../../components/layout/titles/CustomToolbar";
import { Close } from "@mui/icons-material";
import ActionButtonSmall from "../../../components/layout/buttons/action-button-small";
import { GlobalLoaderContext } from "../../../context/global-loader";
import { SnackbarContext } from "../../../context/snackbar";
import { AuthContext, IAuthContext } from "../../../context/auth";
import ApiUri from "../../../api/api-uri";
import API from "../../../api/api";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      position: "relative",
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    textField: {
      marginBottom: "25px",
      padding: "5px",
    },
  })
);

const getLocale = () =>
  navigator.languages && navigator.languages.length
    ? navigator.languages[0]
    : navigator.language || "en-GB";

const parseDate = (date, time) => {
  if (!date || isNaN(new Date(date).getTime())) {
    return ""; // Return empty string or handle invalid date
  }

  let parsed = "";
  time
    ? (parsed = new Intl.DateTimeFormat(getLocale(), {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      }).format(new Date(date)))
    : (parsed = new Intl.DateTimeFormat(getLocale(), {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      }).format(new Date(date)));

  return parsed;
};

const Transition = React.forwardRef(function Transition(
  // made the children prop required
  props: TransitionProps & { children: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ReportSummaryModal = ({ modalOpened, handleClose, statement }) => {
  const classes = useStyles();
  const { user } = useContext<IAuthContext>(AuthContext);
  const { startGlobalLoader, stopGlobalLoader } =
    useContext(GlobalLoaderContext);
  const { initSnackbarError, initSnackbarSuccess } =
    useContext(SnackbarContext);

  const closeReportSummaryModal = () => {
    handleClose();
  };

  return (
    <React.Fragment>
      <Dialog
        open={modalOpened}
        onClose={closeReportSummaryModal}
        TransitionComponent={Transition}
        maxWidth="xl"
      >
        <AppBar className={classes.appBar}>
          <CustomToolbar>
            <Typography variant="h6" className={classes.title}>
              Report Summary
            </Typography>
            <IconButton
              color="inherit"
              onClick={closeReportSummaryModal}
              aria-label="close"
              size="large"
            >
              <Close />
            </IconButton>
          </CustomToolbar>
        </AppBar>
        <Stack spacing={10} padding={5}>
          <Grid spacing={2} container>
            <Grid item xs={12} md={4}>
              <TextField
                value={statement?.file.original_name ?? " "}
                disabled
                label="Filename"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField value={" "} disabled label="Type" fullWidth />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                value={statement?.platform.name ?? " "}
                disabled
                label="Platform"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                value={
                  statement?.status.charAt(0).toUpperCase() +
                  statement?.status.slice(1)
                }
                disabled
                label="State"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                value={parseDate(statement?.created_at, true)}
                disabled
                label="Imported At"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                value={statement?.created_by.email ?? null}
                disabled
                label="Imported By"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                value={statement?.total_rows}
                disabled
                label="Total rows"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                value={statement?.imported_rows}
                disabled
                label="Imported Rows"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                value={statement?.error_rows}
                disabled
                label="Error Rows"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                value={parseDate(statement?.date_from, false)}
                disabled
                label="Date From"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                value={parseDate(statement?.date_to, false)}
                disabled
                label="Date To"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                value={parseDate(statement?.entered_at, false)}
                disabled
                label="Entered At"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                value={statement?.quantity_sum ?? " "}
                disabled
                label="Quantity Sum"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                value={statement?.quantity_sum_imported ?? " "}
                disabled
                label="Quantity Sum Imported"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                value={statement?.quantity_sum_error ?? " "}
                disabled
                label="Quantity Sum Error"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                value={statement?.income_sum ?? " "}
                disabled
                label="Income Sum"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                value={statement?.income_sum_imported ?? " "}
                disabled
                label="Income Sum Imported"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                value={statement?.income_sum_error ?? " "}
                disabled
                label="Income Sum Error"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                value={statement?.tax_country?.country ?? " "}
                disabled
                label="Tax Country"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                value={
                  statement?.tax_percentage
                    ? statement?.tax_percentage + "%"
                    : " "
                }
                disabled
                label="Tax Percentage"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                value={
                  statement?.tax_amount ? statement?.tax_amount + "%" : " "
                }
                disabled
                label="Tax Amount"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                value={statement?.updated_rows}
                disabled
                label="Updated Rows"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                value={
                  statement?.income_conversion_rate
                    ? statement?.income_conversion_rate + "%"
                    : " "
                }
                disabled
                label="Income Conversion Rate"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                value={
                  statement?.tax_conversion_rate
                    ? statement?.tax_conversion_rate + "%"
                    : " "
                }
                disabled
                label="Tax Conversion Rate"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                value={statement?.gbp ? "£" + statement?.gbp : " "}
                disabled
                label="GBP"
                fullWidth
              />
            </Grid>
          </Grid>
          <Stack direction={"row"} spacing={2}>
            <ActionButtonSmall target="_blank" href={statement?.file?.url}>
              Download Original File
            </ActionButtonSmall>
            <ActionButtonSmall
              target="_blank"
              href={statement?.error_file?.url}
            >
              Download Errors
            </ActionButtonSmall>
          </Stack>
        </Stack>
      </Dialog>
    </React.Fragment>
  );
};

export default ReportSummaryModal;
