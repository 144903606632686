import React, { useContext, useEffect, useState } from "react";
import { Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { AuthContext } from "../../../../context/auth";
import { TransitionProps } from "@mui/material/transitions";
import ApiUri from "../../../../api/api-uri";
import API from "../../../../api/api";
import { GlobalLoaderContext } from "../../../../context/global-loader";
import {
    Box,
    Checkbox,
    Chip,
    Divider,
    FormControl,
    FormControlLabel,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Stack,
} from "@mui/material";
import { SnackbarContext } from "../../../../context/snackbar";
import CustomToolbar from "../../../../components/layout/titles/CustomToolbar";
import DateTime from "../../../../components/utilities/date-time";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import DateFnsUtils from "@date-io/date-fns";
import ActionButtonSmall from "../../../../components/layout/buttons/action-button-small";
import { SdCardSharp } from "@mui/icons-material";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        dialog: {
            marginLeft: "40px",
            marginRight: "40px",
            marginTop: "40px",
            marginBottom: "40px",
        },
        appBar: {
            position: "relative",
        },
        title: {
            marginLeft: theme.spacing(2),
            flex: 1,
        },
        textField: {
            marginBottom: "25px",
            padding: "5px",
        },
    })
);

const profile_statuses = [
    { code: "Active", name: "Active" },
    { code: " Closed", name: "Closed" },
    { code: "Suspended", name: "Suspended" },
    { code: "Terminated", name: "Terminated" },
    { code: "All", name: "All" },
];

const subscription_statuses = [
    { code: "active", name: "Active" },
    { code: "inactive", name: "Inactive" },
    { code: "All", name: "All" },
];

const Transition = React.forwardRef(function Transition(
    // made the children prop required
    props: TransitionProps & { children: React.ReactElement },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function ExportSubProfilesModal({ modalOpened, handleClose }) {
    const classes = useStyles();
    const { startGlobalLoader, stopGlobalLoader } =
        useContext(GlobalLoaderContext);
    const { initSnackbarError, initSnackbarSuccess, initSnackbarGeneric } =
        useContext(SnackbarContext);
    const [startDate, setStartDate] = useState(null as any);
    const [endDate, setEndDate] = useState(null);
    const [profile_status, setProfileStatus] = useState<any>(profile_statuses[0]);
    const [subscription_status, setSubscriptionStatus] = useState<any>(
        subscription_statuses[0]
    );
    const { user, userRole } = useContext(AuthContext);

    const closeExportSubProfilesModal = () => {
        setStartDate(null);
        setEndDate(null);
        handleClose();
    };

    const handleStartDateChange = (event) => {
        const newDate = event;
        setStartDate(newDate);
    };
    const handleEndDateChange = (event) => {
        const newDate = event.$d;
        setEndDate(newDate);
    };

    const handleProfileStatusChange = (event) => {
        const profileStatusCode = event.target.value;
        const selectedProfileStatus = profile_statuses.find(
          (profileStatus) => profileStatus.code === profileStatusCode
        );
        setProfileStatus(selectedProfileStatus);
      };

    const handleSubscriptionStatusChange = (event) => {
        const subscriptionStatusCode = event.target.value;
        const selectedSubscriptionStatus = subscription_statuses.find(
          (subscriptionStatus) => subscriptionStatus.code === subscriptionStatusCode
        );
        setSubscriptionStatus(selectedSubscriptionStatus);
      };


    const formatDate = (date) => {
        if (date) {
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, "0");
            const day = String(date.getDate()).padStart(2, "0");
            const formattedDate = `${year}-${month}-${day}`;
            return formattedDate;
        } else {
            // change here if the value should be different when exporting all songs
            return "";
        }
    };

    const handleSave = () => {
        // from web is hardcoded
        const formData = {
            date_begin: formatDate(startDate?.$d),
            date_end: formatDate(endDate),
            profile_status: profile_status.code,
            subscription_status: subscription_status.code,
        };
        startGlobalLoader();
        API.post(
            ApiUri.PROFILE +
              "/" +
              user?.last_accessed_profile?.id +
              ApiUri.DOWNLOAD_SUB_PROFILES,
            formData,
          ).then(
            (response) => {
              const blob = new Blob([response.data], {
                type: response.headers["content-type"],
                // type: "text/csv",
              });
              const downloadUrl = URL.createObjectURL(blob);
      
              const link = document.createElement("a");
              link.href = downloadUrl;
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
      
              URL.revokeObjectURL(downloadUrl);
              stopGlobalLoader();
            },
            (error) => {
              initSnackbarError(error);
              stopGlobalLoader();
            }
          );
    };

    const formIsValid = startDate && endDate;

    return (
        <React.Fragment>
            <Dialog
                className={classes.dialog}
                // fullScreen
                maxWidth="md"
                open={modalOpened}
                onClose={closeExportSubProfilesModal}
                TransitionComponent={Transition}
            >
                <AppBar className={classes.appBar}>
                    <CustomToolbar>
                        <Typography variant="h6" className={classes.title}>
                            Export All Sub Profiles
                        </Typography>
                        <IconButton
                            color="inherit"
                            onClick={closeExportSubProfilesModal}
                            aria-label="close"
                            size="large"
                        >
                            <CloseIcon />
                        </IconButton>
                    </CustomToolbar>
                </AppBar>

                <Stack marginTop={2} padding={3} spacing={2}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Stack spacing={2} direction={"row"}>
                            <DatePicker
                                label="Start Date"
                                value={startDate}
                                onChange={handleStartDateChange}
                                format="DD/MM/YYYY"
                                disableFuture
                                sx={{ width: "100%" }}
                            />
                            <DatePicker
                                label="End Date"
                                value={endDate}
                                onChange={handleEndDateChange}
                                format="DD/MM/YYYY"
                                disableFuture
                                sx={{ width: "100%" }}
                                minDate={startDate}
                            />
                        </Stack>
                        <Stack spacing={2} paddingTop={3} direction={"row"}>
                            <FormControl sx={{ minWidth: 260 }}>
                                <InputLabel>Profile Status</InputLabel>
                                <Select
                                    value={profile_status.code}
                                    onChange={handleProfileStatusChange}
                                    label="profile_status"
                                >
                                    {profile_statuses.map((profile_status) => (
                                        <MenuItem value={profile_status.code} key={profile_status.code}>
                                            {profile_status.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <FormControl sx={{ minWidth: 260 }}>
                                <InputLabel>Subscription Status</InputLabel>
                                <Select
                                    value={subscription_status.code}
                                    onChange={handleSubscriptionStatusChange}
                                    label="subscription_status"
                                >
                                    {subscription_statuses.map((subscription_status) => (
                                        <MenuItem value={subscription_status.code} key={subscription_status.code}>
                                            {subscription_status.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Stack>

                    </LocalizationProvider>
                    <ActionButtonSmall onClick={handleSave} disabled={!formIsValid}>
                        Export
                    </ActionButtonSmall>
                </Stack>
            </Dialog>
        </React.Fragment>
    );
}
