import React, { useContext, useEffect, useState } from "react";
import { Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import ApiUri from "../../../api/api-uri";
import API from "../../../api/api";
import { GlobalLoaderContext } from "../../../context/global-loader";
import Platforms from "../../../enums/platforms";
import {
  Box,
  Checkbox,
  Chip,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import { SnackbarContext } from "../../../context/snackbar";
import CustomToolbar from "../../../components/layout/titles/CustomToolbar";
import DateTime from "../../../components/utilities/date-time";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import DateFnsUtils from "@date-io/date-fns";
import ActionButtonSmall from "../../../components/layout/buttons/action-button-small";
import { SdCardSharp } from "@mui/icons-material";
import { AuthContext } from "../../../context/auth";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialog: {
      marginLeft: "40px",
      marginRight: "40px",
      marginTop: "40px",
      marginBottom: "40px",
    },
    appBar: {
      position: "relative",
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    textField: {
      marginBottom: "25px",
      padding: "5px",
    },
  })
);

const Transition = React.forwardRef(function Transition(
  // made the children prop required
  props: TransitionProps & { children: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function NewMessageModal({
  modalOpened,
  handleClose,
  setMessages,
}) {
  const classes = useStyles();
  const { startGlobalLoader, stopGlobalLoader } =
    useContext(GlobalLoaderContext);
  const { initSnackbarError, initSnackbarSuccess, initSnackbarGeneric } =
    useContext(SnackbarContext);
  const { user } = useContext(AuthContext);
  const [startDate, setStartDate] = useState(null as any);
  const [endDate, setEndDate] = useState(null);
  const [messageContent, setMessageContent] = useState("");
  const [popup, setPopup] = useState(false);

  const closeNewMessageModal = () => {
    setStartDate(null);
    setEndDate(null);
    handleClose();
  };

  const handleStartDateChange = (event) => {
    const newDate = event;
    setStartDate(newDate);
  };
  const handleEndDateChange = (event) => {
    const newDate = event;
    setEndDate(newDate);
  };

  //   const formatDate = (date) => {
  //     if (date) {
  //       const year = date.getFullYear();
  //       const month = String(date.getMonth() + 1).padStart(2, "0");
  //       const day = String(date.getDate()).padStart(2, "0");
  //       const formattedDate = `${year}-${month}-${day}`;
  //       return formattedDate;
  //     } else {
  //       // change here if the value should be different when exporting all songs
  //       return "";
  //     }
  //   };

  const handleSave = () => {
    // from web is hardcoded
    const formData = {
      //   date_begin: formatDate(startDate?.$d),
      visible_from: startDate,
      visible_until: endDate,
      content: messageContent,
      popup: popup,
    };
    startGlobalLoader();
    API.post(
      ApiUri.PROFILE + "/" + user?.last_accessed_profile.id + ApiUri.MESSAGE,
      formData
    ).then(
      (response) => {
        initSnackbarSuccess("Message Added");
        setStartDate(null);
        setEndDate(null);
        setMessageContent("");
        setPopup(false);
        stopGlobalLoader();
        setMessages(null);
        closeNewMessageModal();
      },
      (error) => {
        initSnackbarError(error);
        stopGlobalLoader();
      }
    );
  };

  const handlePopupChange = (event) => {
    const { checked } = event.target;
    setPopup(checked);
  };

  const handleChangeMessageContent = (event) => {
    let value = event.target.value;
    setMessageContent(value);
  };

  const formIsValid = startDate && messageContent;

  return (
    <React.Fragment>
      <Dialog
        className={classes.dialog}
        open={modalOpened}
        onClose={closeNewMessageModal}
        TransitionComponent={Transition}
        maxWidth="md"
      >
        <AppBar className={classes.appBar}>
          <CustomToolbar>
            <Typography variant="h6" className={classes.title}>
              New Message
            </Typography>
            <IconButton
              color="inherit"
              onClick={closeNewMessageModal}
              aria-label="close"
              size="large"
            >
              <CloseIcon />
            </IconButton>
          </CustomToolbar>
        </AppBar>

        <Grid container padding={3} spacing={2}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Grid item xs={12} md={4}>
              <DatePicker
                label="Start Date *"
                value={startDate}
                onChange={handleStartDateChange}
                format="DD/MM/YYYY"
                disablePast
                sx={{ width: "100%" }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <DatePicker
                label="End Date"
                value={endDate}
                onChange={handleEndDateChange}
                format="DD/MM/YYYY"
                disablePast
                sx={{ width: "100%" }}
                minDate={startDate}
              />
            </Grid>
          </LocalizationProvider>
          <Grid item xs={12} md={4}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={popup}
                  onChange={handlePopupChange}
                  color="primary"
                />
              }
              label="Appears as Popup Window"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              multiline
              fullWidth
              rows={4}
              label="Message"
              required
              value={messageContent}
              onChange={handleChangeMessageContent}
            />
          </Grid>
          <Grid item xs={12}>
            <ActionButtonSmall onClick={handleSave} disabled={!formIsValid}>
              Save
            </ActionButtonSmall>
          </Grid>
        </Grid>
      </Dialog>
    </React.Fragment>
  );
}
