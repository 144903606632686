import React, { useContext, useState } from "react";
import { Box, Divider, Link, Menu, MenuItem } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { SnackbarContext } from "../../../context/snackbar";
import { GlobalLoaderContext } from "../../../context/global-loader";
import ConfirmationDialogActivator from "../../../components/confirmation-dialog/confirmation-dialog-activator";
import { Edit, LinkOff, Person } from "@mui/icons-material";
import RoleTypes from "../../../enums/role-types";
import API from "../../../api/api";
import ApiUri from "../../../api/api-uri";
import { Profile } from "../../../models/profile/profile";
import { ProfileRbac } from "../../../models/profile/profile-rbac";
import { AuthContext } from "../../../context/auth";
import ProfileTypes from "../../../enums/profile-types";

const useStyles = makeStyles(() => {
  return {
    menuIcon: {
      "&:hover": {
        cursor: "pointer",
      },
    },
    menuItemIcon: {
      marginRight: 10,
    },
  };
});

const testSite =
  window.location.href.includes("test") ||
  window.location.href.includes("localhost");

export const processFileNameForInvoices = (invoices, user) => {
  return (
    user?.last_accessed_profile?.profile_information?.name +
    "-Earnings Report-" +
    formatReportDates(invoices)
  );
};

const formatReportDates = (reports) => {
  if (!Array.isArray(reports) || reports.length === 0) {
    throw new Error("Input must be a non-empty array.");
  }

  // Extract and sort the dates
  const dates = reports
    .map((report) => new Date(report.date))
    .sort((a, b) => a.getTime() - b.getTime());

  if (dates.length === 1) {
    // If there's only one report
    return formatDate(dates[0]);
  } else {
    // If there are multiple reports
    return `${formatDate(dates[0])} to ${formatDate(dates[dates.length - 1])}`;
  }
};

function formatDate(date) {
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  return `${day}-${month}-${year}`;
}

export default function ContextMenu({ royalty, setRoyalties }) {
  const { user } = useContext(AuthContext);
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const { startGlobalLoader, stopGlobalLoader } =
    useContext(GlobalLoaderContext);
  const { initSnackbarError, initSnackbarSuccess, initSnackbarGeneric } =
    useContext(SnackbarContext);
  const [openUpdateInvoiceModal, setOpenUpdateInvoiceModal] = useState(false);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [year, month, day] = royalty?.date?.split("T")[0].split("-");

  const handleDownloadDetails = () => {
    startGlobalLoader();

    API.post(
      ApiUri.PROFILE +
        "/" +
        user?.last_accessed_profile.id +
        ApiUri.ROYALTY_DOWNLOAD_ERNINGS_PAYMENTS,
      {
        consolidation_array: [
          // {
          //   type: royalty.type,
          //   year: year,
          //   month: month,
          //   object_id: royalty.object_id,
          // },
          royalty,
        ],
      }
    ).then(
      (response) => {
        // const downloadUrl = response.data?.url;
        // const link = document.createElement("a");
        // link.href = downloadUrl;
        // link.download = response?.data?.original_name;
        // document.body.appendChild(link);
        // link.click();
        // document.body.removeChild(link);
        const a = response.headers;
        console.log("🚀 ~ handleDownloadDetails ~ a:", a);
        const blob = new Blob([response.data], {
          type: response.headers["content-type"],
          // type: "text/csv",
        });
        const downloadUrl = URL.createObjectURL(blob);

        const link = document.createElement("a");
        link.href = downloadUrl;
        link.download =
          royalty?.original_name || processFileNameForInvoices([royalty], user);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        // Release the object URL
        URL.revokeObjectURL(downloadUrl);
        stopGlobalLoader();
        handleClose();
      },
      (error) => {
        initSnackbarError(error);
        stopGlobalLoader();
        handleClose();
      }
    );
  };

  const handleDelete = () => {
    startGlobalLoader();

    API.delete(
      ApiUri.ROYALTY_ADJUST + "/" + royalty.object_id + ApiUri.DELETE
    ).then(
      (response) => {
        setRoyalties(null);
        initSnackbarSuccess("Royalty successfully Deleted");
        stopGlobalLoader();
        handleClose();
      },
      (error) => {
        initSnackbarError(error);
        stopGlobalLoader();
        handleClose();
      }
    );
  };

  return (
    <React.Fragment>
      <MoreVertIcon onClick={handleClick} className={classes.menuIcon} />
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {royalty.type === "imported" && (
          <MenuItem onClick={handleDownloadDetails}>Download Details</MenuItem>
        )}
        {royalty.type !== "imported" && (
          <MenuItem key={"dowload invoice"}>
            <a
              style={{ textDecoration: "none", color: "inherit" }}
              href={`https://api.mmpz.${
                testSite ? "test." : ""
              }myclientzone.com/api/v1/royalty-adjust/${
                royalty?.object_id
              }/invoice`}
              target="_blank"
            >
              Download Invoice
            </a>
          </MenuItem>
        )}
        {royalty.type !== "imported" && user?.is_mmpz_service_admin && (
          <MenuItem key={"delete"}>
            <ConfirmationDialogActivator
              onClick={handleDelete}
              type="link"
              actionName="Delete"
            />
          </MenuItem>
        )}
      </Menu>
    </React.Fragment>
  );
}
