import React, { useContext, useEffect, useState } from "react";
import { Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import ApiUri from "../../../../api/api-uri";
import API from "../../../../api/api";
import { GlobalLoaderContext } from "../../../../context/global-loader";
import {
  Box,
  Checkbox,
  Chip,
  CircularProgress,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  Stack,
  TextField,
} from "@mui/material";
import { SnackbarContext } from "../../../../context/snackbar";
import { Composition } from "../../../../models/composition/composition";
import { Song } from "../../../../models/song/song";
import { PlayCircleOutline } from "@mui/icons-material";
import CustomToolbar from "../../../../components/layout/titles/CustomToolbar";
import ActionButtonSmall from "../../../../components/layout/buttons/action-button-small";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialog: {
      marginLeft: "40px",
      marginRight: "40px",
      marginTop: "40px",
    },
  })
);

const Transition = React.forwardRef(function Transition(
  // made the children prop required
  props: TransitionProps & { children: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class DefaultReasons {
  static IncorrectCAE = "Incorrect CAE/IPI number provided";
  static IncorrectPRO = "Incorrect PRO affiliation selected";
  static SongwriterArtist = "Songwriter name appears to be an artist name";
  static LyricalNotAccurate = "Lyrical theme(s) are not accurate";
  static MoodNotAccurate = "Mood(s) are not accurate";
  static GenreNotAccurate = "Genre(s) are not accurate";
  static InstrumentalLyrics =
    "You've selected 'Instrumental' as a tag but have provided lyrics. If you have submitted lyrics, your song cannot be classed as instrumental and vice versa";
}

interface UnsubmitSongModalProps {
  modalOpened: boolean;
  handleClose: () => void;
  composition: Composition;
  setSongs?: any;
}

interface Reason {
  id: number;
  name: string;
}

export default function UnsubmitSongModal({
  modalOpened,
  handleClose,
  composition,
  setSongs,
}: UnsubmitSongModalProps) {
  const { startGlobalLoader, stopGlobalLoader } =
    useContext(GlobalLoaderContext);
  const { initSnackbarError, initSnackbarSuccess } =
    useContext(SnackbarContext);
  const [unsubmissionReasons, setUnsubmissionReasons] = useState<Reason[]>([]);
  const [pickedReasons, setPickedReasons] = useState<Reason[]>([]);
  const [customReason, setCustomReason] = useState("");

  useEffect(() => {
    API.get(ApiUri.UN_SUBMIT_REASON).then(
      (response) => {
        setUnsubmissionReasons(response.data.collection);
      },
      (error) => {
        initSnackbarError(error);
        closeSongModal();
      }
    );
  }, []);

  const closeSongModal = () => {
    handleClose();
  };

  const handleChangeOtherReason = (input) => {
    setCustomReason(input.target.value);
  };

  const handleSave = () => {
    startGlobalLoader();
    const payload = {
      composition_un_submits: pickedReasons.map(function (reason) {
        return {
          unsubmit_reason: {
            id: reason.id,
          },
          message: reason.name === "Other" ? customReason : "",
        };
      }),
    };
    API.post(
      ApiUri.COMPOSITION + "/" + composition.id + ApiUri.UNSUBMIT,
      payload
    ).then(
      (response) => {
        if (setSongs) {
          setSongs(null);
        }
        stopGlobalLoader();
        initSnackbarSuccess("Song successfully unsubmited");
        closeSongModal();
      },
      (error) => {
        initSnackbarError(error);
        stopGlobalLoader();
        closeSongModal();
      }
    );
  };

  const handlePickReason = (reason: Reason) => {
    setPickedReasons((prevPickedReasons) =>
      prevPickedReasons.some((pickedReason) => pickedReason.id === reason.id)
        ? prevPickedReasons.filter(
            (pickedReason) => pickedReason.id !== reason.id
          )
        : [...prevPickedReasons, reason]
    );
  };

  const renderReason = (reason: Reason) => (
    <FormControlLabel
      control={
        <Checkbox
          checked={
            pickedReasons.find(
              (pickedReason) => pickedReason.id === reason.id
            ) !== undefined
          }
          onChange={() => handlePickReason(reason)}
          color="primary"
        />
      }
      label={reason?.name}
    />
  );

  const usingCustomReason =
    pickedReasons.find((pickedReason) => pickedReason.name === "Other") !==
    undefined;

  const formIsValid = pickedReasons.length > 0;

  return (
    <React.Fragment>
      <Dialog
        open={modalOpened}
        onClose={closeSongModal}
        TransitionComponent={Transition}
        sx={{ m: "40px" }}
        maxWidth="md"
      >
        <AppBar position="sticky">
          <CustomToolbar>
            <Typography variant="h6" flex={1}>
              Unsubmit Song
            </Typography>
            <IconButton
              color="inherit"
              onClick={closeSongModal}
              aria-label="close"
              size="large"
            >
              <CloseIcon />
            </IconButton>
          </CustomToolbar>
        </AppBar>

        <Grid container p={4} spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6">Reason for Unsubmission</Typography>
          </Grid>
          <Grid item xs={12}>
            <FormGroup>
              {unsubmissionReasons.length > 0 ? (
                unsubmissionReasons
                  .sort((a, b) =>
                    a.name === "Other" ? 1 : b.name === "Other" ? -1 : 0
                  )
                  .map((reason) => renderReason(reason))
              ) : (
                <CircularProgress color="inherit" size={20} sx={{ pY: 3 }} />
              )}
            </FormGroup>
          </Grid>
          {usingCustomReason && (
            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                rows={4}
                label="Unsubmission reason"
                value={customReason}
                onChange={handleChangeOtherReason}
                required
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <Stack
              direction={"row"}
              spacing={2}
              justifyContent={"flex-end"}
              width={"100%"}
            >
              <ActionButtonSmall onClick={handleClose}>
                Cancel
              </ActionButtonSmall>
              <ActionButtonSmall disabled={!formIsValid} onClick={handleSave}>
                Confirm
              </ActionButtonSmall>
            </Stack>
          </Grid>
        </Grid>
      </Dialog>
    </React.Fragment>
  );
}
