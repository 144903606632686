import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../context/auth";
import ProfileTypes from "../../../enums/profile-types";
import RoleTypes from "../../../enums/role-types";
import { SnackbarContext } from "../../../context/snackbar";
import { GlobalLoaderContext } from "../../../context/global-loader";
import { useHistory } from "react-router-dom";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import ActionButtonSmall from "../../../components/layout/buttons/action-button-small";
import { FileUpload } from "@mui/icons-material";
import API from "../../../api/api";
import ApiUri from "../../../api/api-uri";

const PaymentsImport = () => {
  const { user, userRole } = useContext(AuthContext);
  const { startGlobalLoader, stopGlobalLoader } =
    useContext(GlobalLoaderContext);
  const { initSnackbarSuccess, initSnackbarError } =
    useContext(SnackbarContext);
  const history = useHistory();

  const [startDate, setStartDate] = useState(null as any);
  // const [endDate, setEndDate] = useState(null);
  // const [selectedPlatformID, setSelectedPlatformID] = useState("");
  const [file, setFile] = useState<any>(null);
  const [showPage, setShowPage] = useState(false);

  const seeThisPage =
    (user?.last_accessed_profile.profile_type.name ===
      ProfileTypes.PUBLISHER_ADMINISTRATOR ||
      user?.last_accessed_profile.profile_type.name === ProfileTypes.SERVICE) &&
    (userRole === RoleTypes.ADMIN ||
      userRole === RoleTypes.OWNER ||
      user?.is_mmpz_service_admin);

  // const availablePlatforms = [
  //   { id: 2, name: "MCPS - Mechanical" },
  //   { id: 10, name: "MLC" },
  // ];

  useEffect(() => {
    if (!seeThisPage) {
      initSnackbarError("You don't have permission to access this page.");
      history.push("/dashboard");
      return;
    } else {
      setShowPage(true);
    }
  }, []);

  const handleStartDateChange = (event) => {
    const newDate = event;
    setStartDate(newDate);
  };

  // const handlePlatformChange = (event) => {
  //   setSelectedPlatformID(event.target.value);
  // };

  // const formIsValid = selectedPlatformID && startDate && endDate && file;
  const formIsValid = startDate && file;

  // const formatDate = (date) => {
  //   if (date) {
  //     const year = date.getFullYear();
  //     const month = String(date.getMonth() + 1).padStart(2, "0");
  //     const day = String(date.getDate()).padStart(2, "0");
  //     const formattedDate = `${year}-${month}-${day}`;
  //     return formattedDate;
  //   } else {
  //     // change here if the value should be different when exporting all songs
  //     return "";
  //   }
  // };

  const handleSave = () => {
    startGlobalLoader();

    const formData = new FormData();
    formData.append("entered_date", startDate);
    formData.append("file", file);

    API.post(ApiUri.ROYALTY_ADJUST_PAYMENT_IMPORT, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }).then(
      (response) => {
        stopGlobalLoader();
        initSnackbarSuccess("Report successfully uploaded");
      },
      (error) => {
        stopGlobalLoader();
        initSnackbarError(error);
      }
    );
  };

  const handleUploadFile = function loadFile(event) {
    if (event.target.files.length > 0) {
      //   const url = URL.createObjectURL(event.target.files[0]);
      setFile(event.target.files[0]);
    }
  };

  return (
    showPage && (
      <Grid spacing={2} container>
        <Grid item container xs={12} spacing={2}>
          <Grid item>
            <TextField
              value={file?.name ?? "-"}
              disabled
              label="Upload"
              fullWidth
            />
          </Grid>
          <Grid item>
            <ActionButtonSmall
              component="label"
              sx={{ height: "100%" }}
              startIcon={<FileUpload />}
            >
              Find File
              <input
                hidden
                accept=".csv, .tsv, .txt"
                type="file"
                onChange={handleUploadFile}
              />
            </ActionButtonSmall>
          </Grid>
        </Grid>

        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Grid item xs={12} md={6}>
            <DatePicker
              label="Date"
              value={startDate}
              onChange={handleStartDateChange}
              format="DD/MM/YYYY"
              //   disableFuture
              sx={{ width: "100%" }}
            />
          </Grid>
        </LocalizationProvider>

        {/* <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel>Platform</InputLabel>
            <Select
              value={selectedPlatformID}
              onChange={handlePlatformChange}
              label="Platform"
            >
              {availablePlatforms.map((platform) => (
                <MenuItem value={platform.id} key={platform.id}>
                  {platform.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid> */}

        <Grid item xs={12}>
          <ActionButtonSmall onClick={handleSave} disabled={!formIsValid}>
            Upload
          </ActionButtonSmall>
        </Grid>
      </Grid>
    )
  );
};

export default PaymentsImport;
