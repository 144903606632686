import React, { useCallback, useContext, useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Loader from "../../../components/loader";
import API from "../../../api/api";
import { AuthContext, IAuthContext } from "../../../context/auth";
import ApiUri from "../../../api/api-uri";
import DateTime from "../../../components/utilities/date-time";
import {
  Box,
  debounce,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { CheckCircle, RemoveCircle, Search } from "@mui/icons-material";
// import ContextMenu from "./context-menu";
import { Profile } from "../../../models/profile/profile";
import ProfileTypes from "../../../enums/profile-types";
// import AboutSubProfileModal from "about-sub-profile-modal/index";
import SmallAvatar from "../../../components/avatars/small-avatar";
import { GlobalLoaderContext } from "../../../context/global-loader";
import { SnackbarContext } from "../../../context/snackbar";
import RoleTypes from "../../../enums/role-types";
import { useHistory } from "react-router-dom";
import ContextMenu from "./ContextMenu";

export default function UnsubmissionReasonsTable({
  unsubmissionReasons,
  setUnsubmissionReasons,
}) {
  const { startGlobalLoader, stopGlobalLoader } =
    useContext(GlobalLoaderContext);
  const { initSnackbarError } = useContext(SnackbarContext);
  const { user } = useContext<IAuthContext>(AuthContext);

  const [modalOpened, setModalOpened] = useState(false);
  const [selectedProfile, setSelectedProfile] = useState<Profile>();
  const history = useHistory();

  const reload = () => {
    setUnsubmissionReasons(null);
  };

  return unsubmissionReasons ? (
    <React.Fragment>
      <Paper elevation={3} sx={{ marginBottom: 3 }}>
        <TableContainer>
          <Table>
            <TableHead style={{ borderBottom: "2px solid" }}>
              <TableRow>
                <TableCell>
                  <b>Name</b>
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            {/* <TableBody style={{ cursor: "pointer" }}> */}
            <TableBody>
              {(unsubmissionReasons as any).map((row: any) => {
                return (
                  <TableRow hover key={row.id}>
                    <TableCell>{row?.name}</TableCell>
                    <TableCell>
                      {row?.name !== "Other" && (
                        <ContextMenu reload={reload} reason={row} />
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </React.Fragment>
  ) : (
    <Loader message="Loading Unsubmission Reasons" />
  );
}
