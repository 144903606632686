import React, { useContext, useEffect, useState } from "react";
import {
  AppBar,
  Autocomplete,
  Dialog,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Close } from "@mui/icons-material";
import dayjs from "dayjs";
import ActionButtonSmall from "../../../components/layout/buttons/action-button-small";
import CustomToolbar from "../../../components/layout/titles/CustomToolbar";
import { GlobalLoaderContext } from "../../../context/global-loader";
import { SnackbarContext } from "../../../context/snackbar";
import API from "../../../api/api";
import ApiUri from "../../../api/api-uri";
import useCountries from "../../../hooks/country/countries.hook";
import makeStyles from "@mui/styles/makeStyles";
import { Country } from "../../../models/country/country";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  textField: {
    marginBottom: "25px",
    padding: "5px",
  },
}));

const EditRoyaltyStatementModal = ({
  modalOpened,
  handleClose,
  setImportedRoyaltyStatements,
  statement,
}) => {
  const classes = useStyles();
  const countries = useCountries();
  const updatedCountries = (countries as Country[]).map((obj) => {
    if (obj.country === "Other") {
      return { ...obj, country: "Worldwide" };
    }
    return obj;
  });
  const [editedStatement, setEditedStatement] = useState({ ...statement });
  const { startGlobalLoader, stopGlobalLoader } =
    useContext(GlobalLoaderContext);
  const { initSnackbarError, initSnackbarSuccess } =
    useContext(SnackbarContext);

  useEffect(() => {
    if (modalOpened) {
      // Reset to a fresh copy of the statement when the modal opens
      setEditedStatement({ ...statement });
    }
  }, [modalOpened, statement]);

  const closeEditRoyaltyStatementModal = () => {
    handleClose();
  };

  const handleChange = (event) => {
    const { value, name } = event.target;
    setEditedStatement((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleDueDateChange = (newDate) => {
    setEditedStatement((prevState) => ({
      ...prevState,
      due_date: newDate,
    }));
  };

  const handleEnteredChange = (newDate) => {
    setEditedStatement((prevState) => ({
      ...prevState,
      entered_at: newDate,
    }));
  };

  const handleSave = () => {
    const dateDue = editedStatement.due_date
      ? new Date(editedStatement.due_date)
      : null;
    console.log("🚀 ~ handleSave ~ dateDue:", dateDue);
    const enteredAt = editedStatement.entered_at
      ? new Date(editedStatement.entered_at)
      : null;
    const payload = {
      entered_at: enteredAt ? enteredAt.toISOString().slice(0, -1) : "",
      gbp: parseFloat(editedStatement.gbp),
      due_date: dateDue ? dateDue.toISOString().slice(0, -1) : "",
      tax_country: editedStatement.tax_country, // Now storing the entire country object
      tax_percentage: parseFloat(editedStatement.tax_percentage),
      tax_amount: getTaxAmount(),
    };

    startGlobalLoader("Dots", "Processing, please wait...");
    API.put(
      ApiUri.ROYALTY_IMPORTER_FILE + "/" + editedStatement?.id,
      payload
    ).then(
      (response) => {
        const importRoyaltyStatementId = response.data?.id;
        API.get(
          ApiUri.ROYALTY_IMPORTER_FILE +
            "/" +
            importRoyaltyStatementId +
            ApiUri.PROCESS
        ).then(
          (response) => {
            API.get(
              ApiUri.ROYALTY_IMPORTER_FILE +
                "/" +
                importRoyaltyStatementId +
                ApiUri.CONSOLIDATE
            ).then(
              (response) => {
                initSnackbarSuccess("Royalty Statement edited");
                stopGlobalLoader();
                setImportedRoyaltyStatements(null);
                closeEditRoyaltyStatementModal();
              },
              (error) => {
                stopGlobalLoader();
                initSnackbarError(error);
              }
            );
          },
          (error) => {
            stopGlobalLoader();
            initSnackbarError(error);
          }
        );
      },
      (error) => {
        initSnackbarError(error);
        stopGlobalLoader();
      }
    );
  };

  const getTaxAmount = () => {
    return (
      (parseFloat(editedStatement.gbp) || 0) *
      ((parseFloat(editedStatement.tax_percentage) || 0) / 100)
    );
  };

  return (
    <React.Fragment>
      <Dialog
        open={modalOpened}
        onClose={closeEditRoyaltyStatementModal}
        maxWidth="md"
      >
        <AppBar className={classes.appBar}>
          <CustomToolbar>
            <Typography variant="h6" className={classes.title}>
              Edit Royalty Statement
            </Typography>
            <IconButton
              color="inherit"
              onClick={closeEditRoyaltyStatementModal}
              aria-label="close"
              size="large"
            >
              <Close />
            </IconButton>
          </CustomToolbar>
        </AppBar>

        <Stack spacing={10} padding={5}>
          <Grid spacing={2} container>
            <Grid item xs={12} md={4}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  onChange={handleDueDateChange}
                  format="DD/MM/YYYY"
                  disabled={statement.status === "distributed"}
                  sx={{ width: "100%" }}
                  value={
                    editedStatement?.due_date
                      ? dayjs(editedStatement.due_date)
                      : null
                  }
                  label="Due Date"
                />
              </LocalizationProvider>
            </Grid>

            <Grid item xs={12} md={4}>
              <TextField
                value={editedStatement?.gbp ?? ""}
                onChange={handleChange}
                name="gbp"
                disabled={statement.status === "distributed"}
                label="GBP Amount Received"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">£</InputAdornment>
                  ),
                }}
                fullWidth
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  onChange={handleEnteredChange}
                  format="DD/MM/YYYY"
                  disabled={statement.status === "distributed"}
                  sx={{ width: "100%" }}
                  value={
                    editedStatement?.entered_at
                      ? dayjs(editedStatement.entered_at)
                      : null
                  }
                  label="Entered"
                />
              </LocalizationProvider>
            </Grid>

            <Grid item xs={12} sm={4}>
              <FormControl fullWidth>
                <Autocomplete
                  fullWidth
                  options={updatedCountries} // Pass the list of country objects
                  getOptionLabel={(option) => option.country} // Display country name
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  } // Match by ID
                  value={editedStatement?.tax_country || null} // Store entire country object
                  disabled={statement.status === "distributed"}
                  onChange={(event, newValue) => {
                    setEditedStatement((prevState) => ({
                      ...prevState,
                      tax_country: newValue || null,
                    }));
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Tax Country" />
                  )}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={4}>
              <TextField
                value={editedStatement?.tax_percentage ?? ""}
                onChange={handleChange}
                name="tax_percentage"
                disabled={statement.status === "distributed"}
                label="Tax %"
                fullWidth
                InputProps={{
                  endAdornment: "%",
                }}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <TextField
                value={getTaxAmount()}
                onChange={handleChange}
                name="tax_amount"
                label="Tax Amount"
                disabled
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">£</InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
          <ActionButtonSmall
            onClick={handleSave}
            disabled={statement.status === "distributed"}
          >
            Save
          </ActionButtonSmall>
        </Stack>
      </Dialog>
    </React.Fragment>
  );
};

export default EditRoyaltyStatementModal;
