import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  Link,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Country } from "../models/country/country";
import ActionButtonSmall from "./layout/buttons/action-button-small";
import { AuthContext } from "../context/auth";
import { CustomisationContext } from "../context/customisation";
import useCountries from "../hooks/country/countries.hook";
import { useHistory } from "react-router-dom";
import { GlobalLoaderContext } from "../context/global-loader";
import ApiUri from "../api/api-uri";
import API from "../api/api";
import { SnackbarContext } from "../context/snackbar";
import ProfileTypes from "../enums/profile-types";

const NewProfileForm = ({ parent, profileType }) => {
  const { customisation } = useContext(CustomisationContext);
  const { user, setUser, setUserRole } = useContext(AuthContext);
  const [addressLine1, setAddressLine1] = useState("");
  const [addressLine2, setAddressLine2] = useState("");
  const [postcode, setPostcode] = useState("");
  const [buttonColor, setButtonColor] = useState("#000000");
  const [agreedWithContract, setAgreedWithContract] = useState(false);
  const { startGlobalLoader, stopGlobalLoader } =
    useContext(GlobalLoaderContext);
  const { initSnackbarError, initSnackbarSuccess } =
    useContext(SnackbarContext);
  const [signatureId, setSignatureId] = useState("");

  let profileTypeToUse =
    profileType.toUpperCase() === ProfileTypes.PUBLISHER.toUpperCase()
      ? ProfileTypes.PUBLISHER
      : ProfileTypes.SONGWRITER;

  const profileTypeIsValid =
    profileTypeToUse === ProfileTypes.SONGWRITER ||
    profileTypeToUse === ProfileTypes.PUBLISHER;

  const testSite =
    window.location.href.includes("test") ||
    window.location.href.includes("localhost");

  const useHorusMusicPublishingAndSongwriter = !parent;
  if (useHorusMusicPublishingAndSongwriter) {
    parent = "horus-music-publishing";
    profileTypeToUse = ProfileTypes.SONGWRITER;
  }

  // get the parent id
  useEffect(() => {
    API.get("/public" + ApiUri.PROFILE + "/" + parent).then(
      (response) => {
        const responseProfile = response.data;
        setButtonColor(responseProfile.profile_information.button_color);
        API.get(ApiUri.PROFILE_CAE_NUMBER, {
          params: { cae_number: responseProfile.cae_number },
        }).then(
          (response) => {
            const parentProfileObject = response.data;
            const newProfile = profile;
            newProfile.parent.id = parentProfileObject.id;
            newProfile.profile_information.background_color =
              parentProfileObject?.profile_information?.background_color ??
              profile.profile_information.background_color;
            newProfile.profile_information.main_color =
              parentProfileObject?.profile_information?.main_color ??
              profile.profile_information.main_color;
            newProfile.profile_information.text_color =
              parentProfileObject?.profile_information?.text_color ??
              profile.profile_information.text_color;
            newProfile.profile_information.inner_color =
              parentProfileObject?.profile_information?.inner_color ??
              profile.profile_information.inner_color;
            newProfile.profile_information.button_color =
              parentProfileObject?.profile_information?.button_color ??
              profile.profile_information.button_color;
            newProfile.profile_information.file =
              parentProfileObject?.profile_information?.file ??
              profile.profile_information.file;
            new setProfile(newProfile);
          },
          (err) => {
            initSnackbarError(err);
          }
        );
      },
      (err) => {}
    );
  }, []);

  const initialProfile = {
    profile_type: {
      name: profileTypeToUse,
    },
    enabled: true,
    status: "Active",
    signup_private: true,
    profile_information: {
      name: "",
      email: user?.email,
      country: {
        id: "",
      },
      city: "",
      address: "",
      main_color: customisation?.mainColor,
      text_color: customisation?.textColor,
      background_color: customisation?.backgroundColor,
      inner_color: "#2ccce4",
      button_color: customisation?.buttonColor,
      file: null,
      phone_number: "",
    },
    parent: {
      id: 1,
      profile_information: {
        name: parent,
      },
    },
  };

  const [profile, setProfile] = useState(initialProfile);
  const countries = useCountries();
  const history = useHistory();

  const handleChange = (event: any) => {
    const { name, value } = event.target;
    const newProfile = profile;
    switch (name) {
      case "name":
        newProfile.profile_information.name = value;
        break;
      case "addressLine1":
        setAddressLine1(value);
        break;
      case "addressLine2":
        setAddressLine2(value);
        break;
      case "city":
        newProfile.profile_information.city = value;
        break;
      case "postcode":
        setPostcode(value);
        break;
      case "country":
        newProfile.profile_information.country.id = value;
        break;
      case "phoneNumber":
        newProfile.profile_information.phone_number = value;
        break;
    }
    setProfile((oldProfile) => {
      return { ...oldProfile, ...newProfile };
    });
  };

  const handleAgreementChange = (event) => {
    const { checked } = event.target;
    if (checked) {
      if (testSite) {
        startGlobalLoader();
        API.post(ApiUri.PROFILE + ApiUri.SIGNATURE).then(
          (response) => {
            setSignatureId(response.data.id);
            setAgreedWithContract(checked);
            stopGlobalLoader();
          },
          (error) => {
            stopGlobalLoader();
            initSnackbarError(error);
          }
        );
      } else {
        setAgreedWithContract(checked);
      }
    } else {
      setAgreedWithContract(checked);
    }
  };

  const handleRegister = () => {
    const updatedProfile = profile;
    updatedProfile.profile_information.address =
      addressLine1 + addressLine2 + postcode;

    startGlobalLoader();
    API.post(
      ApiUri.PROFILE + "/" + profile.parent.id + ApiUri.SUB_PROFILE_FROM_MMAZ,
      updatedProfile
    ).then(
      (response) => {
        const newProfile = response.data;
        if (newProfile.id) {
          if (testSite) {
            API.put(
              ApiUri.PROFILE +
                "/" +
                newProfile.id +
                ApiUri.SIGNATURE +
                "/" +
                signatureId,
              profile
            ).then(
              (response) => {
                const emailQuery = { email: user?.email };
                API.get("/invitation", { params: emailQuery }).then(
                  (response) => {
                    const invitations = response.data.collection;
                    const newNotAcceptedInvitations = invitations.filter(
                      (invitation) => invitation?.accepted_at === null
                    );
                    const payloadObject = {
                      user: {
                        id: user?.id,
                        email: user?.email,
                      },
                      role: newNotAcceptedInvitations[0].role,
                    };
                    API.post(
                      ApiUri.PROFILE +
                        "/" +
                        newNotAcceptedInvitations[0].profile.id +
                        ApiUri.RBAC,
                      payloadObject
                    ).then(
                      (response) => {
                        if (response.data?.id) {
                          API.get(ApiUri.USER_ME).then((response) => {
                            setUser(response.data);
                            setUserRole(newNotAcceptedInvitations[0].role);
                            stopGlobalLoader();
                            initSnackbarSuccess(
                              "Profile Successfully created. Welcome!"
                            );
                            history.push("/dashboard");
                          });
                        }
                      },
                      (error) => {
                        stopGlobalLoader();
                        initSnackbarError(error);
                      }
                    );
                  },
                  (error) => {
                    initSnackbarError(error);
                    stopGlobalLoader();
                  }
                );
              },
              (error) => {
                initSnackbarError(error);
                stopGlobalLoader();
              }
            );
          } else {
            const emailQuery = { email: user?.email };
            API.get("/invitation", { params: emailQuery }).then(
              (response) => {
                const invitations = response.data.collection;
                const newNotAcceptedInvitations = invitations.filter(
                  (invitation) => invitation?.accepted_at === null
                );
                const payloadObject = {
                  user: {
                    id: user?.id,
                    email: user?.email,
                  },
                  role: newNotAcceptedInvitations[0].role,
                };
                API.post(
                  ApiUri.PROFILE +
                    "/" +
                    newNotAcceptedInvitations[0].profile.id +
                    ApiUri.RBAC,
                  payloadObject
                ).then(
                  (response) => {
                    if (response.data?.id) {
                      API.get(ApiUri.USER_ME).then((response) => {
                        setUser(response.data);
                        setUserRole(newNotAcceptedInvitations[0].role);
                        stopGlobalLoader();
                        initSnackbarSuccess(
                          "Profile Successfully created. Welcome!"
                        );
                        history.push("/dashboard");
                      });
                    }
                  },
                  (error) => {
                    stopGlobalLoader();
                    initSnackbarError(error);
                  }
                );
              },
              (error) => {
                initSnackbarError(error);
                stopGlobalLoader();
              }
            );
          }
        }
      },
      (error) => {
        initSnackbarError(error);
        stopGlobalLoader();
      }
    );
    // register this profile:
    // updatedProfile
  };

  const nameLabel = profileTypeToUse + " " + "Profile Name";

  const disableContractCheckbox =
    !profile.profile_information.name ||
    !addressLine1 ||
    !profile.profile_information.phone_number ||
    !profile.profile_information.city ||
    !postcode ||
    !profile.profile_information.country.id;

  return (
    <Grid
      container
      item
      component={Paper}
      xs={11}
      md={7}
      marginTop={1}
      paddingY={2}
      paddingX={3}
      elevation={5}
      spacing={3}
    >
      <Grid item xs={12} md={12}>
        <Stack alignItems={"center"}>
          <Typography>User Info</Typography>
        </Stack>
      </Grid>
      <Grid item xs={12} md={3}>
        <TextField
          value={user?.user_profile.first_name}
          disabled
          label="First Name"
          fullWidth
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <TextField
          value={user?.user_profile.last_name}
          disabled
          label="Last Name"
          fullWidth
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField value={user?.email} disabled label="Email" fullWidth />
      </Grid>
      <Grid item xs={12} md={12}>
        <Stack alignItems={"center"}>
          <Typography>
            {profileTypeToUse + " "}
            Profile Info
          </Typography>
        </Stack>
      </Grid>
      {profileTypeToUse === ProfileTypes.SONGWRITER && (
        <Grid item xs={12} md={12} marginTop={-2}>
          <ul>
            <li>
              Profile Name should be the legal name of the Songwriter, formatted
              Firstname Lastname.
            </li>
            <li>
              The profile name will be used when registering your songs at the
              PRO’s so please make sure this is accurate else it will delay the
              registration of your songs.
            </li>
            <li>
              If you are already a member of a PRO, please make sure your
              profile name matches the name that you used when signing up with
              your society.
            </li>
            <li>Please do not use your Artist name or Band name.</li>
          </ul>
        </Grid>
      )}
      <Grid item xs={12} md={12}>
        <TextField
          value={profile.profile_information.name}
          name="name"
          label={nameLabel}
          required
          onChange={handleChange}
          fullWidth
        />
      </Grid>
      <Grid item xs={6} md={6}>
        <TextField
          value={addressLine1}
          name="addressLine1"
          label="Address Line 1"
          required
          onChange={handleChange}
          fullWidth
        />
      </Grid>
      <Grid item xs={3} md={3}>
        <TextField
          value={addressLine2}
          name="addressLine2"
          label="Address Line 2"
          onChange={handleChange}
          fullWidth
        />
      </Grid>
      <Grid item xs={3} md={3}>
        <TextField
          value={profile.profile_information.phone_number}
          name="phoneNumber"
          label="Phone Number"
          onChange={handleChange}
          fullWidth
          required
        />
      </Grid>
      <Grid item xs={8} md={4}>
        <TextField
          value={profile.profile_information.city}
          name="city"
          label="City"
          required
          onChange={handleChange}
          fullWidth
        />
      </Grid>
      <Grid item xs={4} md={4}>
        <TextField
          value={postcode}
          name="postcode"
          label="Postcode"
          required
          onChange={handleChange}
          fullWidth
        />
      </Grid>
      <Grid item xs={4} md={4}>
        <FormControl fullWidth>
          <InputLabel required>Country</InputLabel>
          <Select
            name="country"
            value={profile.profile_information.country.id}
            required
            onChange={handleChange}
            label="Country"
          >
            {countries.map((country: Country) => (
              <MenuItem key={country.id} value={country.id}>
                {country.country}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={12} md={12}>
        <FormControlLabel
          control={
            <Checkbox
              checked={agreedWithContract}
              onChange={handleAgreementChange}
              color="primary"
              disabled={disableContractCheckbox}
            />
          }
          label={
            <Typography>
              I confirm that I have read and agreed with the{" "}
              {/* <Link component="button" onClick={handleOpenContractPage}> */}
              <a
                // component="button"
                href={parent + "/" + profileTypeToUse + "/public/terms-of-use"}
                target="_blank"
              >
                Terms & Conditions
              </a>{" "}
              *
            </Typography>
          }
        />
      </Grid>

      <Grid item xs={12} md={12}>
        <Stack alignItems={"center"}>
          <ActionButtonSmall
            onClick={handleRegister}
            customColor={buttonColor}
            disabled={!agreedWithContract}
          >
            Register
          </ActionButtonSmall>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default NewProfileForm;
