import {
  Grid,
  FormControl,
  TextField,
  Card,
  Fab,
  Typography,
  Stack,
} from "@mui/material";
import React, { useState } from "react";
import { Delete } from "@mui/icons-material";
import { Check } from "@material-ui/icons";
import { Song } from "../../../../models/song/song";
import { RecordingArtist } from "../../../../models/song/recording-artist";

interface IArtistForm {
  artist?: RecordingArtist;
  song: Song;
  createArtistCallback: Function;
  deleteArtistCallback: Function;
}

const newArtistInitialData = {
  name: "",
  main: false,
};

export default function ArtistForm({
  artist,
  song,
  createArtistCallback,
  deleteArtistCallback,
}: IArtistForm) {
  const [formData, setFormData] = useState(artist || newArtistInitialData);

  const handleFieldChange = (event: any) => {
    const { name, value } = event.target;
    setFormData((previousState) => ({ ...previousState, [name]: value }));
  };

  const handleSaveClick = () => {
    if (formData.name.trim()) {
      // Save the artist and pass it to the callback
      createArtistCallback(formData);
    }
  };

  const handleDeleteClick = () => {
    // Call the delete callback if the artist is already saved
    deleteArtistCallback(formData);
  };

  return (
    <React.Fragment>
      <Card elevation={4} sx={{ p: 2, width: "50%" }}>
        <Grid container spacing={2} alignItems={"center"}>
          {!artist ? (
            <React.Fragment>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <TextField
                    name="name"
                    value={formData.name}
                    onChange={handleFieldChange}
                    required={true}
                    type="text"
                    label="Artist Name"
                  />
                </FormControl>
              </Grid>
              <Grid item>
                <Fab size="small" color="primary" onClick={handleSaveClick}>
                  <Check />
                </Fab>
              </Grid>
            </React.Fragment>
          ) : (
            <Grid item xs={10}>
              <Typography>{formData.name}</Typography>
            </Grid>
          )}
          {artist && (
            <Grid item xs={2}>
              <Stack direction={"row"} justifyContent={"flex-end"}>
                <Fab size="small" onClick={handleDeleteClick}>
                  <Delete />
                </Fab>
              </Stack>
            </Grid>
          )}
        </Grid>
      </Card>
    </React.Fragment>
  );
}
