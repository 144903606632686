import { Stack } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import ActionButtonSmall from "../../../components/layout/buttons/action-button-small";
import { GlobalLoaderContext } from "../../../context/global-loader";
import ApiUri from "../../../api/api-uri";
import API from "../../../api/api";
import { AuthContext } from "../../../context/auth";
import { SnackbarContext } from "../../../context/snackbar";
import ProfileTypes from "../../../enums/profile-types";
import { useHistory } from "react-router-dom";
import NewMessageModal from "./NewMessageModal";
import MessagesTable from "./MessagesTable";

const MessageBoard = () => {
  const [messages, setMessages] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(0);
  const [newMessageModalOpened, setNewMessageModalOpened] = useState(false);

  const history = useHistory();

  const { startGlobalLoader, stopGlobalLoader } =
    useContext(GlobalLoaderContext);
  const { user } = useContext(AuthContext);
  const { initSnackbarError, initSnackbarSuccess } =
    useContext(SnackbarContext);

  const seeThisPage =
    user?.is_mmpz_service_admin &&
    (user?.last_accessed_profile.profile_type.name ===
      ProfileTypes.PUBLISHER_ADMINISTRATOR ||
      user?.last_accessed_profile.profile_type.name === ProfileTypes.SERVICE);

  useEffect(() => {
    if (!seeThisPage) {
      initSnackbarError("You don't have permission to access this page.");
      history.push("/dashboard");
      return;
    }
    if (!messages) {
      startGlobalLoader();
      API.get(
        ApiUri.PROFILE + "/" + user?.last_accessed_profile.id + ApiUri.MESSAGE,
        {
          params: {
            limit: rowsPerPage,
            page: page + 1,
            search_term: searchTerm,
          },
        }
      ).then(
        (response) => {
          setTotalRows(response.data.meta.total);
          setMessages(response.data.collection);
          stopGlobalLoader();
        },
        (error) => {
          initSnackbarError(error);
          stopGlobalLoader();
        }
      );
    }
  }, [messages]);

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
    setMessages(null);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(event.target.value);
    setPage(0);
    setMessages(null);
  };

  return (
    <>
      <Stack spacing={2}>
        <ActionButtonSmall onClick={() => setNewMessageModalOpened(true)}>
          Add New Message
        </ActionButtonSmall>
        <MessagesTable
          messages={messages}
          setMessages={setMessages}
          totalRows={totalRows}
          rowsPerPage={rowsPerPage}
          page={page}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Stack>
      <NewMessageModal
        modalOpened={newMessageModalOpened}
        handleClose={() => {
          setNewMessageModalOpened(false);
        }}
        setMessages={setMessages}
      />
    </>
  );
};

export default MessageBoard;
