import {
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import ApiUri from "../../api/api-uri";
import API from "../../api/api";
import { Composition } from "../../models/composition/composition";
import ActionButtonSmall from "../layout/buttons/action-button-small";
import SmallLoader from "../small-loader";

function isObject(value) {
  return typeof value === "object" && value !== null && !Array.isArray(value);
}

interface IDeliverySplitsPreviewDialog {
  composition: Composition;
  open: boolean;
  closeDialog: Function;
}

export default function DeliverySplitsPreviewDialog({
  composition,
  open,
  closeDialog,
}: IDeliverySplitsPreviewDialog) {
  const [formData, setFormData] = useState<any>([]);

  useEffect(() => {
    API.get(
      ApiUri.COMPOSITION + "/" + composition?.id + ApiUri.DELIVERY_SPLITS
    ).then((response) => {
      if (isObject(response.data)) {
        setFormData([response.data]);
      } else {
        setFormData(response.data);
      }
    });
  }, []); // eslint-disable-line

  return formData ? (
    <Dialog disableEscapeKeyDown open={open} fullWidth>
      <DialogTitle>Delivery Splits Preview</DialogTitle>

      <DialogContent>
        {formData.map((split: any, index: number) => {
          return (
            <React.Fragment key={index}>
              <Grid
                container
                direction="column"
                justifyContent="flex-start"
                alignItems="stretch"
                spacing={3}
              >
                <Card elevation={4} style={{ padding: 20, margin: 20 }}>
                  <Grid item xs={12}>
                    <Typography variant="overline">Performance</Typography>
                    <Typography>
                      {split?.performance?.profile?.profile_information?.name} (
                      {split?.performance?.profile_split} %)
                    </Typography>
                    <Typography>
                      {split?.performance?.parent?.profile_information?.name} (
                      {split?.performance?.parent_split} %)
                    </Typography>
                  </Grid>

                  <br></br>

                  <Grid item xs={12}>
                    <Typography variant="overline">Mechanical</Typography>
                    <Typography>
                      {split?.mechanical?.profile?.profile_information?.name} (
                      {split?.mechanical?.profile_split} %)
                    </Typography>
                    <Typography>
                      {split?.mechanical?.parent?.profile_information?.name} (
                      {split?.mechanical?.parent_split} %)
                    </Typography>
                  </Grid>

                  <br></br>

                  <Grid item xs={12}>
                    <Typography variant="overline">Sync</Typography>
                    <Typography>
                      {split?.sync?.profile?.profile_information?.name} (
                      {split?.sync?.profile_split} %)
                    </Typography>
                    <Typography>
                      {split?.sync?.parent?.profile_information?.name} (
                      {split?.sync?.parent_split} %)
                    </Typography>
                  </Grid>
                </Card>
              </Grid>
            </React.Fragment>
          );
        })}
      </DialogContent>

      <DialogActions>
        <ActionButtonSmall onClick={closeDialog}>Close</ActionButtonSmall>
      </DialogActions>
    </Dialog>
  ) : (
    <SmallLoader />
  );
}
