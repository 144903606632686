import React, { useContext, useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import { AuthContext, IAuthContext } from "../../../context/auth";
import ContextMenu from "./context-menu";
import { CircularProgress } from "@mui/material";
import { CheckCircle, RemoveCircle } from "@mui/icons-material";

// const formatDate = (date) => {
//   if (date) {
//     const [year, month, day] = date.split("T")[0].split("-");
//     return `${day.padStart(2, "0")}/${month.padStart(2, "0")}/${year}`;
//   } else {
//     return "-";
//   }
// };

const formatDate = (date) => {
  const parsedDate = new Date(date);

  const day = String(parsedDate.getDate()).padStart(2, "0");
  const month = String(parsedDate.getMonth() + 1).padStart(2, "0"); // Note: Month is 0-based, so we add 1.
  const year = parsedDate.getFullYear();

  const formattedDateTime = `${day}/${month}/${year}`;
  return formattedDateTime;
};

export default function MessagesTable({
  messages,
  setMessages,
  totalRows,
  rowsPerPage,
  page,
  handleChangePage,
  handleChangeRowsPerPage,
}) {
  return (
    <React.Fragment>
      <Paper elevation={3}>
        <TableContainer>
          <Table>
            <TableHead style={{ borderBottom: "2px solid" }}>
              <TableRow>
                <TableCell>
                  <b>Created</b>
                </TableCell>
                <TableCell>
                  <b>Message Text</b>
                </TableCell>
                <TableCell>
                  <b>Visible From</b>
                </TableCell>
                <TableCell>
                  <b>Visible Until</b>
                </TableCell>
                <TableCell>
                  <b>Created By</b>
                </TableCell>
                <TableCell>
                  <b>Appers as Popup Window</b>
                </TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {messages !== null && messages?.length > 0 ? (
                messages.map((row, index) => (
                  <TableRow key={`message${index}`}>
                    <TableCell>{formatDate(row.created_at)}</TableCell>
                    <TableCell>{row.content}</TableCell>
                    <TableCell>{formatDate(row.visible_from)}</TableCell>
                    <TableCell>{formatDate(row.visible_until)}</TableCell>
                    <TableCell>
                      {row.created_by
                        ? `${row.created_by?.user_profile?.first_name} ${row.created_by?.user_profile?.last_name}`
                        : "-"}
                    </TableCell>
                    <TableCell>
                      {row.popup ? (
                        <CheckCircle color="primary" />
                      ) : (
                        <RemoveCircle color="primary" />
                      )}
                    </TableCell>
                    <TableCell>
                      <ContextMenu message={row} setMessages={setMessages} />
                    </TableCell>
                  </TableRow>
                ))
              ) : messages === null ? (
                <TableRow>
                  <TableCell colSpan={7} align="center">
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              ) : (
                <TableRow>
                  <TableCell colSpan={7} align="center"></TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100]}
          component="div"
          count={totalRows}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </React.Fragment>
  );
}
