import React, { useContext, useEffect, useState } from "react";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import ActionButtonSmall from "../../../../components/layout/buttons/action-button-small";
import API from "../../../../api/api";
import ApiUri from "../../../../api/api-uri";
import { AuthContext, IAuthContext } from "../../../../context/auth";
import { SnackbarContext } from "../../../../context/snackbar";
import { GlobalLoaderContext } from "../../../../context/global-loader";
import ProfileTypes from "../../../../enums/profile-types";
import useCanCreatePublisherAdministrator from "../../../../hooks/profile-rbac/publisher-administrator-rbac.hook";
import useCanCreatePublisher from "../../../../hooks/profile-rbac/publisher-rbac.hook";
import useCanCreateSongwriter from "../../../../hooks/profile-rbac/songwriter-rbac.hook";
import { useHistory } from "react-router-dom";
import MmdzProfilesAutocomplete from "./mmdz-profiles-autocomplete";
import { TwoFactorAuthenticationModal } from "../../../../components/TwoFactorAuthenticationModal";
import RoleTypes from "../../../../enums/role-types";

const useStyles = makeStyles(() => {
  return {
    container: {
      minHeight: 200,
      padding: 20,
    },
  };
});

interface FormData {
  profile_information: {
    name: string;
    email: string;
  };
  profile_type: {
    name: string;
  };
  mmdz_profile?: object;
  signup_private: boolean;
}

const formDataInitial: FormData = {
  profile_information: {
    name: "",
    email: "",
  },
  profile_type: {
    name: ProfileTypes.SONGWRITER,
  },
  signup_private: false,
};

export default function NewSubProfileForm() {
  const classes = useStyles();
  const history = useHistory();
  const canCreatePublisherAdministrator = useCanCreatePublisherAdministrator();
  const canCreatePublisher = useCanCreatePublisher();
  const canCreateSongwriter = useCanCreateSongwriter();
  const { user } = useContext<IAuthContext>(AuthContext);
  const { startGlobalLoader, stopGlobalLoader } =
    useContext(GlobalLoaderContext);
  const { initSnackbarSuccess, initSnackbarError } =
    useContext(SnackbarContext);
  const [currentUserRole, setCurrentUserRole] = useState(null);
  const [formData, setFormData] = useState(formDataInitial);
  const [
    twoFactorAuthenticationModalOpen,
    setTwoFactorAuthenticationModalOpen,
  ] = useState(false);

  useEffect(() => {
    startGlobalLoader();
    API.get(ApiUri.PROFILE + "/" + user?.last_accessed_profile.id).then(
      (response) => {
        const profile = response.data;
        const userRole = profile?.profile_rbac?.find(
          (profile) => profile.user.id === user?.id
        )?.role.name;
        setCurrentUserRole(userRole);
        stopGlobalLoader();
      },
      (error) => {
        initSnackbarError(error);
        stopGlobalLoader();
      }
    );
  }, []);

  const handleFieldChange = (event: any) => {
    const { name, value } = event.target;

    if (name === "profile_information.name") {
      formData.profile_information.name = value;
    }

    if (name === "profile_information.email") {
      formData.profile_information.email = value;
    }

    if (name === "profile_type.name") {
      formData.profile_type.name = value;
    }

    if (name === "signup_private") {
      formData.signup_private = event.target.checked;
    }

    setFormData((previousState) => ({ ...previousState, ...formData }));
  };

  const mmdzProfilesAutocompleteOnChange = (value) => {
    if (typeof value === "object") {
      formData.profile_information.name = value.profileInformation?.name;
      formData.mmdz_profile = value;
    } else {
      formData.profile_information.name = value;
    }

    setFormData((previousState) => ({ ...previousState, ...formData }));
  };

  const handleSave = (event: any) => {
    event.preventDefault();
    user?.twoFactorAuthenticationEnabled
      ? setTwoFactorAuthenticationModalOpen(true)
      : handleSuccess();
  };

  const handleSuccess = () => {
    startGlobalLoader();

    API.post(
      ApiUri.PROFILE +
        "/" +
        user?.last_accessed_profile.id +
        ApiUri.SUB_PROFILE,
      formData
    ).then(
      (response) => {
        stopGlobalLoader();
        initSnackbarSuccess("New sub profile successfully created");
        history.push("/sub-profiles/manage");
      },
      (error) => {
        stopGlobalLoader();
        initSnackbarError(error);
      }
    );
  };

  return (
    <React.Fragment>
      <Paper elevation={3} className={classes.container}>
        <form autoComplete="off" onSubmit={handleSave}>
          <Grid container spacing={4}>
            {user?.is_mmpz_service_admin ? (
              <Grid item xs={12} sm={4}>
                <FormControl fullWidth style={{ padding: 5 }}>
                  <MmdzProfilesAutocomplete
                    autocompleteOnChange={mmdzProfilesAutocompleteOnChange}
                    profileType={formData.profile_type?.name}
                  />
                </FormControl>
              </Grid>
            ) : (
              <Grid item xs={12} sm={4}>
                <FormControl fullWidth style={{ padding: 5 }}>
                  <TextField
                    name="profile_information.name"
                    value={formData.profile_information?.name}
                    required={true}
                    type="text"
                    label="Profile Name"
                    placeholder="Enter your profile name"
                    onChange={handleFieldChange}
                    disabled={
                      currentUserRole !== RoleTypes.OWNER &&
                      currentUserRole !== RoleTypes.ADMIN &&
                      !user?.is_mmpz_service_admin
                    }
                  />
                </FormControl>
              </Grid>
            )}

            <Grid item xs={12} sm={4}>
              <FormControl fullWidth style={{ padding: 5 }}>
                <TextField
                  name="profile_information.email"
                  value={formData.profile_information.email}
                  required={true}
                  type="email"
                  label="Email Address"
                  placeholder="Enter your email address"
                  onChange={handleFieldChange}
                  disabled={
                    currentUserRole !== RoleTypes.OWNER &&
                    currentUserRole !== RoleTypes.ADMIN &&
                    !user?.is_mmpz_service_admin
                  }
                />
              </FormControl>
            </Grid>
            {(canCreatePublisher || canCreatePublisherAdministrator) && (
              <Grid item xs={12} sm={4}>
                <FormControl fullWidth style={{ padding: 5 }}>
                  <InputLabel>Profile Type *</InputLabel>
                  <Select
                    name="profile_type.name"
                    required={true}
                    value={formData.profile_type?.name}
                    onChange={handleFieldChange}
                    label="Profile Type"
                    disabled={
                      currentUserRole !== RoleTypes.OWNER &&
                      currentUserRole !== RoleTypes.ADMIN &&
                      !user?.is_mmpz_service_admin
                    }
                  >
                    <MenuItem
                      value={ProfileTypes.PUBLISHER_ADMINISTRATOR}
                      disabled={!canCreatePublisherAdministrator}
                    >
                      {ProfileTypes.PUBLISHER_ADMINISTRATOR}
                    </MenuItem>
                    <MenuItem
                      value={ProfileTypes.PUBLISHER}
                      disabled={!canCreatePublisher}
                    >
                      {ProfileTypes.PUBLISHER}
                    </MenuItem>
                    <MenuItem
                      value={ProfileTypes.SONGWRITER}
                      disabled={!canCreateSongwriter}
                    >
                      {ProfileTypes.SONGWRITER}
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            )}
          </Grid>

          {formData.profile_type?.name !== ProfileTypes.SONGWRITER ? (
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formData.signup_private}
                        onChange={handleFieldChange}
                        name="signup_private"
                        color="primary"
                        disabled={
                          currentUserRole !== RoleTypes.OWNER &&
                          currentUserRole !== RoleTypes.ADMIN &&
                          !user?.is_mmpz_service_admin
                        }
                      />
                    }
                    label="Sign up under this profile should be private?"
                  />
                </FormGroup>
              </Grid>
            </Grid>
          ) : null}

          <Grid
            style={{ marginTop: 40 }}
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <ActionButtonSmall
              type="submit"
              disabled={
                currentUserRole !== RoleTypes.OWNER &&
                currentUserRole !== RoleTypes.ADMIN &&
                !user?.is_mmpz_service_admin
              }
            >
              Save
            </ActionButtonSmall>
          </Grid>
        </form>
      </Paper>
      {twoFactorAuthenticationModalOpen && (
        <TwoFactorAuthenticationModal
          modalOpened={twoFactorAuthenticationModalOpen}
          handleClose={() => setTwoFactorAuthenticationModalOpen(false)}
          onSuccess={handleSuccess}
        />
      )}
    </React.Fragment>
  );
}
