import React, { useContext, useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import { AuthContext, IAuthContext } from "../../../context/auth";
import ContextMenu from "./context-menu";
import { CircularProgress, Typography } from "@mui/material";

const formatDate = (date) => {
  if (date) {
    const [year, month, day] = date.split("T")[0].split("-");
    return `${day.padStart(2, "0")}/${month.padStart(2, "0")}/${year}`;
  } else {
    return "";
  }
};

export default function RoyaltiesTable({
  royalties,
  setRoyalties,
  selectedRows,
  setSelectedRows,
  totalRows,
  rowsPerPage,
  page,
  handleChangePage,
  handleChangeRowsPerPage,
}) {
  const { user } = useContext<IAuthContext>(AuthContext);

  // Toggle selection of a row
  const handleSelectRow = (index) => {
    setSelectedRows((prevSelected) =>
      prevSelected.includes(index)
        ? prevSelected.filter((i) => i !== index)
        : [...prevSelected, index]
    );
  };

  // Toggle select all rows
  const handleSelectAll = () => {
    if (royalties) {
      if (selectedRows.length === royalties.length) {
        setSelectedRows([]);
      } else {
        setSelectedRows(royalties.map((_, index) => index));
      }
    }
  };

  return (
    <React.Fragment>
      <Paper elevation={3}>
        <TableContainer>
          <Table>
            <TableHead style={{ borderBottom: "2px solid" }}>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    indeterminate={
                      royalties &&
                      selectedRows.length > 0 &&
                      selectedRows.length < royalties.length
                    }
                    checked={
                      royalties && selectedRows.length === royalties.length
                    }
                    onChange={handleSelectAll}
                  />
                </TableCell>
                <TableCell>
                  <b>Date</b>
                </TableCell>
                <TableCell>
                  <b>Description</b>
                </TableCell>
                <TableCell>
                  <b>Total Income (GBP)</b>
                </TableCell>
                <TableCell>
                  <b>Profile Earnings (GBP)</b>
                </TableCell>
                <TableCell>
                  <b>Payments (GBP)</b>
                </TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {royalties ? (
                royalties.map((row, index) => (
                  <TableRow key={`royalty${index}`}>
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={selectedRows.includes(index)}
                        onChange={() => handleSelectRow(index)}
                      />
                    </TableCell>
                    <TableCell> {formatDate(row.date)}</TableCell>
                    <TableCell>{row.description}</TableCell>
                    <TableCell>
                      {row.total_income
                        ? `${row.total_income.toFixed(2)}`
                        : "-"}
                    </TableCell>
                    <TableCell>
                      {row.profile_earnings
                        ? `${row.profile_earnings.toFixed(2)}`
                        : "-"}
                    </TableCell>
                    <TableCell>
                      <span
                        style={{ color: row.payments < 0 ? "red" : "inherit" }}
                      >
                        {row.payments ? `${row.payments.toFixed(2)}` : "-"}
                      </span>
                    </TableCell>
                    <TableCell>
                      <ContextMenu royalty={row} setRoyalties={setRoyalties} />
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={7} align="center">
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100]}
          component="div"
          count={totalRows}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </React.Fragment>
  );
}
