import React from "react";

export default function DateTime(props: any) {
  // const getLocale = () =>
  //   navigator.languages && navigator.languages.length
  //     ? navigator.languages[0]
  //     : navigator.language || "en-GB";
  const getLocale = () => "en-GB";

  return (
    <React.Fragment>
      {props.children
        ? props.onlyDate
          ? new Intl.DateTimeFormat(getLocale(), {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            }).format(new Date(props.children))
          : new Intl.DateTimeFormat(getLocale(), {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
              hour: "2-digit",
              minute: "2-digit",
            }).format(new Date(props.children))
        : null}
    </React.Fragment>
  );
}
