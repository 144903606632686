export default class ApiUri {
  static MMDZ = "/mmdz";
  static MMPZ = "/mmpz";

  static USER = "/user";
  static USER_ME = "/me";
  static RBAC = "/rbac";
  static TOKEN_REFRESH = "/token/refresh";
  static INVITE_USER = "/invite-user";
  static ALL = "/all";
  static CLOSE = "/close";
  static SUSPEND = "/suspend";
  static TERMINATE = "/terminate";
  static DISABLE = "/disable";
  static ENABLE = "/enable";
  static DELETE = "/delete";
  static REACTIVE = "/reactive";
  static CONSOLIDATE = "/consolidate";

  static ACCOUNTS_APP_REGISTRATION = "/accounts-app-registration";
  static PROFILE = "/profile";
  static PROFILE_CAE_NUMBER = "/profile-cae-number";
  static SUB_PROFILE = "/sub-profile";
  static SUB_PROFILE_FROM_MMAZ = "/sub-profile-from-mmaz";
  static SELECT_PROFILE = "/select-profile";
  static SEARCH_PROFILE = "/search-profile";
  static CONNECT_PROFILE = "/connect-profile";
  static PROFILE_SOCIAL_MEDIA = "/social-media";
  static DELIVERY_SPLITS = "/delivery-splits";
  static ROYALTY_SPLITS = "/royalty-splits";
  static ROYALTY_SPLITS_TEMPLATES = "/royalty-splits-templates";
  static ROYALTY_IMPORTER_FILE = "/royalty-importer-file";
  static ROYALTY_ADJUST = "/royalty-adjust";
  static ROYALTY_ADJUST_PAYMENT_IMPORT = "/royalty-adjust-payment-import";
  static ROYALTY_DOWNLOAD_ERNINGS_PAYMENTS =
    "/royalty-download-ernings-payments";

  static BY_ISRC = "/by-isrc";

  static RE_IMPORT_ERRORS = "/re-import-errors";

  static ARTIST = "/artist";

  static DOWNLOAD_SONGS = "/download-songs";

  static DOWNLOAD_SUB_PROFILES = "/download-subprofile";

  static FUNCTIONALITY = "/functionality";

  static COMPOSITION_STATUS_CHANGE = "/composition-status-change";
  static COMPOSITION_UNSUBMISSION_LIST = "/composition-unsubmission-list";

  static UN_SUBMIT_REASON = "/un-submit-resason";

  static MESSAGE = "/message";
  static READER = "/reader";

  static PACKAGE = "/package";
  static PACKAGE_BASIC_SUBSCRIPTION = "/package/basic-subcription";
  static PAYMENT_GATEWAY = "/payment-gateway";
  static PLUS = "/plus";

  static COUNTRY = "/country";

  static NOTIFICATIONS = "/notifications";
  static NOTIFICATION = "/notification";
  static READ_ALL = "/read-all";

  static FILE = "/file";

  static COMPOSITION = "/composition";
  static SUBMIT = "/submit";
  static TERRITORY = "/territory";

  static SONG = "/song";
  static VERSION_FROM_URL = "/version-from-url";
  static SONG_WRITER = "/song-writer";
  static PERFORMER = "/performer";
  static VERSION = "/version";
  static ARTWORK = "/artwork";
  static ARTWORK_FROM_URL = "/artwork-from-url";
  static PROCESS = "/process";
  static UNSUBMIT = "/unsubmit";
  static PUBLISH = "/publish";
  static TAKE_DOWN = "/take_down";
  static SONGS = "/songs";

  static SYNC = "/sync";
  static TAGS = "/tags";
  static INSTRUMENT = "/instrument";
  static LYRICAL_THEME = "/lyrical-theme";
  static GENRE = "/genre";
  static MOOD = "/mood";
  static TEMPO = "/tempo";
  static VOCALS = "/vocals";
  static TYPE = "/type";

  static PRO_AFFILIATION = "/pro-affiliation";

  static PUBLISHING = "/publishing";
  static PLATFORM = "/platform";
  static PLATFORM_IPI_NUMBER = "/platform-ipi-number";
  static LANGUAGE = "/language";
  static CUSTOM_LANGUAGE = "/custom-language";

  static CONTRACT = "/contract";
  static SIGNATURE = "/signature";
  static GET_CONTRACT = "/get-contract";

  static FEED = "/feed";
  static CWR = "/cwr";
  static LYRICFIND = "/lyricfind";
  static IPRS = "/iprs";
  static SOUNDMOUSE = "/soundmouse";

  static INVOICE = "/invoice";

  static DOWNLOAD = "/download";

  static COMMUNICATION_SETTINGS = "/communication-settings";
  static DASHBOARD = "/dashboard";
  static LOGIN = "/login";
  static HELPER = "/helper";
  static CHECK_PRICE = "/check-price";
  static ERROR = "/error";
  static TRANSACTION = "/transaction";
  static MANUAL_PROCESS = "/manualProcesss";
}
