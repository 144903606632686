import React, { useCallback, useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Loader from "../../../../components/loader";
import API from "../../../../api/api";
import ApiUri from "../../../../api/api-uri";
import DateTime from "../../../../components/utilities/date-time";
import { Box, debounce, InputAdornment, Stack, TextField } from "@mui/material";
import { Search } from "@mui/icons-material";
import { Profile } from "../../../../models/profile/profile";

export default function ReleaseUnsubmissionsTable() {
  const [unsubmissions, setUnsubmissions] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    let isMounted = true;
    if (unsubmissions) {
      return;
    }

    API.get(ApiUri.COMPOSITION_UNSUBMISSION_LIST, {
      params: {
        limit: rowsPerPage,
        page: page + 1,
        search_term: searchTerm,
      },
    }).then((response) => {
      if (isMounted) {
        setTotalRows(response.data.meta.total);
        setUnsubmissions(response.data.collection);
      }
    });

    return () => {
      isMounted = false;
    };
  }, [unsubmissions]); // eslint-disable-line

  const reload = () => {
    setUnsubmissions(null);
  };

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
    reload();
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(event.target.value);
    setPage(0);
    reload();
  };

  const debouncedSearchTermInput = useCallback(
    debounce(() => {
      setPage(0);
      reload();
    }, 800),
    []
  );

  const handleChangeSearchBy = (event) => {
    setSearchTerm(event.target.value);
    debouncedSearchTermInput();
  };

  return unsubmissions ? (
    <Stack>
      <Box mb={1} display="flex" flexDirection="row-reverse">
        <TextField
          label="Search"
          focused={true}
          value={searchTerm}
          onChange={handleChangeSearchBy}
          variant="outlined"
          size="small"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Search />
              </InputAdornment>
            ),
          }}
        />
      </Box>

      <Paper elevation={3} sx={{ marginBottom: 3 }}>
        <TableContainer>
          <Table>
            <TableHead style={{ borderBottom: "2px solid" }}>
              <TableRow>
                <TableCell>
                  <b>ISWC</b>
                </TableCell>
                <TableCell>
                  <b>Song Name</b>
                </TableCell>
                <TableCell>
                  <b>Composer</b>
                </TableCell>
                <TableCell>
                  <b>Unsubmission Reason</b>
                </TableCell>
                <TableCell>
                  <b>Unsubmitted By</b>
                </TableCell>
                <TableCell>
                  <b>Unsubmitted At</b>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(unsubmissions as any).map((row) => {
                return (
                  <TableRow hover key={row.id}>
                    <TableCell>{row?.composition?.ISWC ?? "-"}</TableCell>
                    <TableCell>{row?.composition?.title ?? "-"}</TableCell>
                    <TableCell>
                      {row?.composition?.song_writers
                        .map(
                          (writer) => writer?.profile?.profile_information?.name
                        )
                        .join(", ") ?? "-"}
                    </TableCell>
                    <TableCell>
                      {row?.un_submit_reason ? (
                        <ul style={{ paddingLeft: "20px" }}>
                          {row?.un_submit_reason.map((reason) => (
                            <li>
                              {reason?.name === "Other"
                                ? row?.notes
                                : reason?.name}
                            </li>
                          ))}
                        </ul>
                      ) : (
                        "-"
                      )}
                    </TableCell>
                    <TableCell>
                      {row?.unsubmitted_by?.user_profile
                        ? row?.unsubmitted_by?.user_profile?.first_name +
                          " " +
                          row?.unsubmitted_by?.user_profile?.last_name
                        : "-"}
                    </TableCell>
                    <TableCell>
                      <DateTime>{row?.unsubmitted_at ?? "-"}</DateTime>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100]}
          component="div"
          count={totalRows}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Stack>
  ) : (
    <Loader message="Loading Unsubmissions" />
  );
}
