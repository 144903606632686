import { AxiosResponse } from "axios";
import React, { createContext, useState } from "react";
import API from "../api/api";
import ApiUri from "../api/api-uri";
import { User } from "../models/user/user";
import * as SnackBar from "node-snackbar";
import "node-snackbar/dist/snackbar.css";
import RoleTypes from "../enums/role-types";

export interface IAuthContext {
  user?: User;
  setUser?: any;
  userRole?: RoleTypes;
  setUserRole: any;
}

export const AuthContext = createContext({} as IAuthContext);

const testSite =
  window.location.hostname.includes("test") ||
  window.location.hostname.includes("localhost");

const AuthContextProvider = ({ children }: any) => {
  const [user, setUser] = useState<User | undefined>(undefined);
  const [userRole, setUserRole] = useState<RoleTypes | undefined>(undefined);

  if (
    !user &&
    !userRole &&
    window.location.pathname !== "/accounts-app-registration"
  ) {
    SnackBar.show({
      showAction: false,
      text: "Please wait, loading application ...",
      pos: "bottom-center",
      duration: 4000,
    });

    API.get(ApiUri.USER_ME).then((response: AxiosResponse) => {
      const userObj: User = response.data;
      if (!userObj?.applications?.includes("mmpz")) {
        window.location.replace(
          `https://mmaz.${testSite ? "test." : ""}myclientzone.com` +
            "/dashboard?ref=mmpz&error=no_auth"
        );
      } else if (userObj?.last_accessed_profile?.status !== "Active") {
        const activeProfile = userObj?.main_profiles.find(
          (profile) => profile.status === "Active"
        );
        if (activeProfile) {
          API.get(ApiUri.SELECT_PROFILE + "/" + activeProfile.id).then(
            (response) => {
              window.location.href = "/";
            }
          );
        } else {
          window.location.replace(
            `https://mmaz.${testSite ? "test." : ""}myclientzone.com` +
              "/dashboard?ref=mmpz&error=no_auth"
          );
        }
      } else {
        if (userObj?.last_accessed_profile?.id) {
          API.get(
            ApiUri.PROFILE + "/" + userObj?.last_accessed_profile?.id
          ).then((response) => {
            const profileObj = response.data;
            const currentUserRole = profileObj?.profile_rbac.find(
              (profile) => profile.user.id === userObj?.id
            )?.role.name;
            setUserRole(userRole ?? currentUserRole ?? RoleTypes.READ_ONLY);
            setUser(userObj);
          });
        } else {
          setUserRole(userRole ?? RoleTypes.READ_ONLY);
          setUser(userObj);
        }
      }
    });
  }

  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
        userRole,
        setUserRole,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
