import { Link } from "@mui/material";
import { Stack } from "@mui/system";
import React from "react";

const AppIcon = (props: any) => {
  return (
    <Link href={props.linkUrl} underline="none" target="_blank">
      <Stack
        // width={props.small ? "80px" : "auto"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <img
          className={props.small ? "app-icon-small" : "app-icon"}
          src={props.imageSource}
          style={{ paddingBottom: props.small ? "2px" : "0px" }}
        />
        <Stack
          alignItems="center"
          padding={props.small ? "0px" : "10px"}
          width={props.small ? "80%" : "auto"}
        >
          {props.small ? (
            <b style={{ wordBreak: "break-word", textAlign: "center" }}>
              {props.appName}
            </b>
          ) : (
            <h3 style={{ wordBreak: "break-word", textAlign: "center" }}>
              {props.appName}
            </h3>
          )}
        </Stack>
      </Stack>
    </Link>
  );
};

export default AppIcon;
